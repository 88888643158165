import React from 'react';
import './Sidenav.css';
import { Link } from 'react-router-dom'
import { BarChart2, Bookmark, Home, Users, Share, Clock } from 'react-feather';

function Sidenav({ currentUserData }) {
  return (
    <>
      <nav className='sidenav'>
        <ul className='navbar-nav pt-3'>
          <li className='nav-item navigation mt-3'>
            <Link to="/dashboard" className='nav-link '>
                <Home className='nav-icon' /><span className="RouteNames "> Dashboard</span>
            </Link>
          </li>
          <li className='nav-item navigation mt-3'>
            <Link to="/timelines" className='nav-link '>
                <Clock className='nav-icon' /><span className="RouteNames ">  Timelines</span>
            </Link>
          </li>
          <li className='nav-item navigation mt-3'>
            <Link to="/bookmark" className='nav-link '>
                <Bookmark className='nav-icon' /><span className="RouteNames">  My Bookmarks</span>
            </Link>
          </li>
          <li className='nav-item navigation mt-3'>
            <Link to="/myinsights" className='nav-link '>
                <BarChart2 className='nav-icon' /> <span className="RouteNames ">  My Insights</span>
            </Link>
          </li>
          <li className='nav-item navigation mt-3'>
            <Link to="/sharedtrax" className='nav-link' activeClassName="nav-link--active">
                <Share className='nav-icon' /><span className="RouteNames ">  Shared Trax</span>
            </Link>
          </li>
          {currentUserData.role === "MANAGER" && (<li className='nav-item navigation mt-3'>
            <Link to="/manageusers" className='nav-link '>
                <Users className='nav-icon' /> <span className="RouteNames">  Manage Users</span>
            </Link>
          </li>)}
          {/* Super Admin Menu end*/}
          {currentUserData.role === "SUPERADMIN" && ( <li className='nav-item navigation mt-3'>
            <Link to="/OrgAndAdmin" className='nav-link '>
                <Share className='nav-icon' /><span className="RouteNames "> Org and Admin</span>
            </Link>
          </li>)}
          {currentUserData.role === "ADMIN" && ( <li className='nav-item navigation mt-3'>
            <Link to="/TeamAndAdmin" className='nav-link '>
                <Share className='nav-icon' /><span className="RouteNames "> Team and Users</span>
            </Link>
          </li>)}
          {/* super Admin Menu stop */}
        </ul>
      </nav>
    </>
  )
}

export default Sidenav