
import React, { useContext, useState } from 'react';
import './ManageUsers.css';
import { UserPlus } from 'react-feather';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import GroupIcon from '@mui/icons-material/Group';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Typography from '@mui/material/Typography';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import { DialogBox } from '../../common/ModelDialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import useAxios from '../../../helpers/Hooks/useAxios';
import { URLS } from "../../../helpers/URLS";
import { getUserData } from '../../../Utlities/utlities';
import LoaderAndToasterContext from '../../../Context/LoaderAndToaster';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Active from '../assets/active.png';
import InActive from '../assets/inactive.png';
import Avatar from '@mui/material/Avatar';
import { useHistory } from 'react-router-dom';
import { X, Save } from 'react-feather';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const ManagerUsers = () => {
    const history = useHistory();
    const { fetchData } = useAxios();
    const { id } = getUserData();
    const [modelPopup, setModelPopup] = useState(false);
    const { messageAlert, setCurrentUseData, currentUserData } = useContext(LoaderAndToasterContext);
    const [error, setError] = React.useState([]);
    const [userList, setUserList] = useState([]);
    const [userMasterList, setMasterUserList] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [userData, setUserData] = React.useState({
        isEdit: false,
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        birthDate: null,
        title: "Mr",
        image_url: "",
        gender: "male",
        role: "USER"
    });
    const modelHandle = () => {
        if (modelPopup) {
            ReserForm();
        }
        setError([])
        setModelPopup(!modelPopup)
    }

    const ReserForm = () => {
        setUserData({
            isEdit: false,
            firstname: "",
            lastname: "",
            mobile: "",
            email: "",
            birthDate: null,
            title: "Mr",
            image_url: "",
            gender: "male",
            role: "USER"
        })
    }
    const onChangeHandler = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }
    const getAllUsers = async () => {
        const data = await fetchData({ url: URLS.GET_MANAGER_USER, method: 'get', body: null, headersData: "" });
        if (data.status === 200) {
            setUserList([...data.data]);
            setMasterUserList([...data.data])
        }

    }
    React.useEffect(() => {
        if (id) {
            getAllUsers()
        }
    }, [id])
    React.useEffect(() => {
        const keyValues = ["email", "firstname", "gender", "lastname", "mobile", "role", "title"]
        if (searchInput) {
            const search = userMasterList.filter(item => {
                let status = false;
                keyValues.forEach(key => {
                    if (item[key] && item[key].toLowerCase().includes(searchInput.toLowerCase())) {
                        status = true;
                    }
                })
                return status;
            })
            // console.log(item)
            setUserList([...search])

        } else {
            setUserList([...userMasterList])
        }
    }, [searchInput])



    return (
        <>
            <div className='MangeUsers'>
                <div className='Manageusers-searchtab shadow'>
                    <div>
                        <Paper className='Manageusers-search-bar'>
                            <InputBase
                                onChange={(e) => setSearchInput(e.target.value)}
                                value={searchInput}
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search user"
                                inputProps={{ 'aria-label': 'search google maps' }}
                            />
                            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>

                        </Paper>
                    </div >
                    <div className='Manageusers-add-button'>
                        <button className=' Manageuser-btn'
                            onClick={modelHandle}>
                            <UserPlus /> &nbsp;Add User
                        </button>
                    </div>

                </div>
                <div className='card shadow Manageusers-Table'>
                    <div >
                        <Table aria-label="simple table">
                            <TableHead className='Manageusers-table-header'>
                                <TableRow >
                                    <TableCell align='center' className='Mangeusers-table-heading'>First Name</TableCell>
                                    <TableCell align='center' className='Mangeusers-table-heading'>Last Name</TableCell>
                                    <TableCell align='center' className='Mangeusers-table-heading'>Email</TableCell>
                                    <TableCell align='center' className='Mangeusers-table-heading'>Mobile</TableCell>
                                    <TableCell align='center' className='Mangeusers-table-heading'>DOB</TableCell>
                                    <TableCell align='center' className='Mangeusers-table-heading'>Gender</TableCell>
                                    <TableCell align='center' className='Mangeusers-table-heading'>Role</TableCell>
                                    <TableCell align='center' className='Mangeusers-table-heading'>Status</TableCell>
                                    <TableCell align='center' className='Mangeusers-table-heading'>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ fontStyle: 'normal', }}>
                                {userList.map(row => <TableRow hover key={row.email} >
                                    <TableCell align='center'>{row.firstname}</TableCell>
                                    <TableCell align='center'>{row.lastname}</TableCell>
                                    <TableCell align='center'>{row.email}</TableCell>
                                    <TableCell align='center'>{row.mobile}</TableCell>
                                    <TableCell align='center'>{moment(row.birthDate).format("YYYY-MM-DD")}</TableCell>
                                    <TableCell align='center'>{row.gender}</TableCell>
                                    <TableCell align='center'>{row.role}</TableCell>
                                    <TableCell align='center'>
                                        <Avatar sx={{
                                            width: '20px',
                                            height: '20px',
                                            cursor: 'pointer',
                                        }}
                                            onClick={async () => {
                                                const data = await fetchData({
                                                    url: URLS.user, method: 'put', body: {
                                                        activeStatus: !row.activeStatus,
                                                        email: row.email
                                                    }, headersData: ""
                                                });
                                                if (data.status === 200) {
                                                    messageAlert(data.message, "success")
                                                    getAllUsers();

                                                } else {
                                                    messageAlert(data.message, "error")
                                                }

                                            }} alt={row.email} src={row.activeStatus ? Active : InActive} />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <EditIcon style={{
                                            marginRight: '10px',
                                            cursor: 'pointer',
                                        }} onClick={() => {
                                            setUserData({
                                                isEdit: true,
                                                ...row
                                            })
                                            modelHandle()
                                        }} />
                                        <VisibilityIcon onClick={() => history.push(`/UserTimeLine/${row._id}`)} style={{
                                            cursor: 'pointer',
                                        }} />
                                        {/* <Chip icon={<Active />} label="Call me" /> */}

                                    </TableCell>
                                </TableRow>)}

                            </TableBody>
                        </Table>
                    </div>

                </div>

                {/* Model start */}
                <DialogBox
                    onClose={modelHandle}
                    aria-labelledby="customized-dialog-title"
                    open={modelPopup}
                    // fullWidth
                    maxWidth="md"

                >
                    <DialogTitle id="customized-dialog-title"
                        style={{ backgroundColor: '#25478e', color: '#fff', padding: '10px' }}
                        onClose={modelHandle} >
                        <h4>Add User</h4>
                    </DialogTitle>
                    <DialogContent dividers  >

                        <Typography gutterBottom>
                            <Box
                                component="div"
                                sx={{
                                    textAlign: 'center',

                                    '& .MuiFormControl-root': { m: 2 },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div className='Form-view'>
                                    <div className='d-flex'>
                                        <TextField
                                            sx={{ m: 1, width: "40ch" }}
                                            onChange={onChangeHandler}
                                            value={userData.firstname}
                                            error={error[0]}
                                            helperText={error[0]}
                                            name="firstname"
                                            id="outlined-basic"
                                            label="First Name"
                                            variant="outlined" />
                                        <TextField
                                            sx={{ m: 1, width: "40ch" }}
                                            onChange={onChangeHandler}
                                            name='lastname'
                                            value={userData.lastname}
                                            error={error[1]}
                                            helperText={error[1]}
                                            id="outlined-basic"
                                            label="Last Name"
                                            variant="outlined" />
                                    </div>
                                    <div className='d-flex'>
                                        <TextField
                                            sx={{ m: 1, width: "40ch" }}
                                            error={error[5]}
                                            helperText={error[5]}
                                            value={userData.email}
                                            disabled={userData.isEdit}
                                            name="email"
                                            id="outlined-basic"
                                            label="Email"
                                            onChange={onChangeHandler}
                                            variant="outlined" />
                                        <TextField
                                            sx={{ m: 1, width: "40ch" }}
                                            onChange={onChangeHandler}
                                            error={error[2]}
                                            helperText={error[2]}
                                            name='mobile'
                                            value={userData.mobile}
                                            id="outlined-basic"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            label="Mobile Number"
                                            variant="outlined" />
                                    </div>

                                    <div className='d-flex'>
                                        <div className='DOBField'>
                                            <TextField
                                                sx={{ m: 1, width: "30ch" }}
                                                className='Field-Names'
                                                onChange={onChangeHandler}
                                                error={error[3]}
                                                helperText={error[3]}
                                                id="date"
                                                name='birthDate'
                                                label="Date of birth"
                                                value={moment(userData.birthDate).format("YYYY-MM-DD")}
                                                type="date"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                        {/* <div className='RoleField'>
                                            <FormControl sx={{ m: 1, width: "20ch" }}>
                                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={userData.role}
                                                    label="Role"
                                                    name="role"
                                                    onChange={onChangeHandler}
                                                >
                                                    <MenuItem value={"USER"}>USER</MenuItem>
                                                    <MenuItem value={"MANAGER"}>MANAGER</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div> */}
                                        <div className='GenderControl'>
                                            <FormControl>
                                                <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    value={userData.gender}
                                                    name="gender"
                                                    onChange={onChangeHandler}
                                                >
                                                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                                                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    {/* <FormControl width={40}>
                                        <InputLabel id="demo-simple-select-label">Title</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={userData.title}
                                            label="Title"
                                            name="title"
                                            onChange={onChangeHandler}
                                        >
                                            <MenuItem value={"Mr"}>Mr</MenuItem>
                                            <MenuItem value={"Ms"}>Ms</MenuItem>
                                            <MenuItem value={"Mrs"}>Mrs</MenuItem>
                                        </Select>
                                    </FormControl> */}


                                </div>
                            </Box>
                        </Typography>


                    </DialogContent>
                    <DialogActions style={{ backgroundColor: '#9bbde0', color: '#4a6ca1' }}>
                        <Button
                            style={{ backgroundColor: 'red', color: '#fff' }} variant="contained" onClick={(e) => {
                                modelHandle()
                            }} ><X sx={{ fontSize: '25px' }} />&nbsp;Cancel</Button>

                        <Button
                            style={{ backgroundColor: 'green', color: '#fff', paddingLeft: "10px" }}
                            onClick={async (e) => {

                                const customError = [];
                                const validateEmail = (email) => {
                                    return String(email)
                                        .toLowerCase()
                                        .match(
                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        );
                                };
                                if (!userData.email) {
                                    customError[5] = 'Email is required';
                                }
                                if (userData.email) {

                                    if (!validateEmail(userData.email)) {
                                        customError[5] = "Email is not valid";
                                    }
                                }
                                if (!userData.firstname) {
                                    customError[0] = "First Name is required";
                                }
                                if (!userData.lastname) {
                                    customError[1] = "Last Name is required";
                                }
                                if (!userData.mobile) {
                                    customError[2] = "Mobile is required";
                                }
                                if (userData.mobile) {
                                    if (userData.mobile.length !== 10) {
                                        customError[2] = "Mobile must be 10 digits";
                                    }

                                }
                                if (!userData.birthDate) {
                                    customError[3] = "Birth Date is required";
                                }

                                if (!userData.gender) {
                                    customError[4] = "Gender is required"
                                }
                                setError(customError)
                                if (customError.length) {
                                    return null;
                                } else {
                                    setError([])
                                }

                                if (userData.isEdit) {
                                    const data = await fetchData({ url: URLS.user, method: 'put', body: { ...userData }, headersData: "" });
                                    if (data.status === 200) {
                                        messageAlert(data.message, "success")
                                        modelHandle();
                                        getAllUsers();

                                    } else {
                                        messageAlert(data.message, "error")
                                    }


                                } else {
                                    const data = await fetchData({ url: URLS.user, method: 'post', body: { ...userData }, headersData: "" });
                                    if (data.code) {
                                        messageAlert(data.message[0], "error")
                                    } else {
                                        messageAlert(data.message, "success")
                                        getAllUsers();
                                        modelHandle();
                                    }
                                }
                            }}

                        >
                            <Save sx={{ fontSize: '25px' }} />&nbsp;
                            {userData.isEdit ? "UPDATE" : "Save"}
                        </Button>


                        {/* <Button
                            style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
                        >
                            Close
                        </Button> */}

                    </DialogActions>

                </DialogBox>
                {/* model end */}
            </div >
        </>
    )
}
export default ManagerUsers;