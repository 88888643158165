import React, { useContext, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles'
import useAxios from '../../../helpers/Hooks/useAxios';
import { URLS } from "../../../helpers/URLS";
import { getUserData } from '../../../Utlities/utlities';
import LoaderAndToasterContext from '../../../Context/LoaderAndToaster';
import { useParams } from "react-router-dom";
import { Buffer } from 'buffer';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TimeLineMap from '../Dashboard/TimeLineMap';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles({
    paper: {
        padding: "8px",
        textAlign: 'left',
        fontWeight: "fontWeightBold",
        fontSize: "24px",
    },
});


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const SharedBookmarkorTrax = () => {
    const [isLoader, setIsLoader] = useState(true);
    const classes = useStyles();
    const { fetchData } = useAxios();
    const { id } = getUserData();
    const { messageAlert, setCurrentUseData, currentUserData } = useContext(LoaderAndToasterContext);
    const { sharedid } = useParams();
    const [imagesList, setImagesList] = useState([]);
    const [places, setPlaces] = useState([]);
    const [details, setDetails] = useState({
        email: "",
        firstname: "",
        lastname: "",
        bookmarkName: "",
        type: "",
        createdOn: "",
        expiresAt: "",
        isSharingImages: ""
    });
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getSharedList = async () => {
        try {
            const data = await fetchData({ url: URLS.GET_SHARE.replace("@userId", id).replace("@shareId", sharedid), method: 'get', body: null, headersData: "", headerParameters: "" });
            setDetails({
                email: data.senderDetail.email,
                firstname: data.senderDetail.firstname,
                lastname: data.senderDetail.lastname,
                bookmarkName: data.share.name,
                type: data.share.type,
                createdOn: data.share.createdOn,
                expiresAt: data.share.expiresAt,
                isSharingImages: data.share.isSharingImages
            })
            if (data.share.type === "Trax") {
                setValue(1)
            }
            const images = await fetchData({ url: URLS.BOOKMARK_DELETE.replace("@senderId", data.share.sender).replace("@shareId", sharedid) + 'images', method: 'get', body: null, headersData: "", headerParameters: "" });
            setImagesList(images)
            const places = await fetchData({ url: URLS.BOOKMARK_DELETE.replace("@senderId", data.share.sender).replace("@shareId", sharedid) + 'places', method: 'get', body: null, headersData: "", headerParameters: "" });
            const p = places.response.docs.map(x => {
                return {
                    userPlace: {
                        name: x.name,
                        loc: {
                            coordinates: [Number(x.loc.split(",")[1]), Number(x.loc.split(",")[0])]
                        }
                    }
                }
            })
            setPlaces([...p])
            setIsLoader(false)

        }
        catch (err) {
            console.log("getSharedList-ERROR", err);
        }
    };

    useEffect(() => {
        getSharedList();
    }, [sharedid]);

    const chooseSelectData = (data) => {

    }


    // console.log("sharedid:",sharedid)
    return (<Box m={2} sx={{ flexGrow: 1 }}>
        {/* Loader */}
        <Backdrop
            sx={{ color: '#fff', zIndex: 9999 }}
            open={isLoader}
        >
            <CircularProgress color="inherit" />

        </Backdrop>
        {/* Loader */}
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Item>
                    <Box display={"flex"} className={classes.paper}>
                        <Typography sx={{
                            width: "33%",
                        }} variant="body1" color="textSecondary" component="p" >
                            <b> Name </b>: {details.bookmarkName}
                        </Typography>
                        <Typography sx={{
                            width: "33%",
                        }} variant="body1" color="textSecondary" component="p" >
                            <b> Type </b>: {details.type}
                        </Typography>
                        <Typography sx={{
                            width: "33%",
                        }} variant="body1" color="textSecondary" component="p" >
                            <b> Sent </b>: {details.createdOn ? moment(details.createdOn).format("YYYY-MM-DD  HH:mm:ss A") : " - "}
                        </Typography>

                    </Box>
                    <Box display={"flex"} className={classes.paper}>
                        <Typography sx={{
                            width: "33%",
                        }} variant="body1" color="textSecondary" component="p" >
                            <b> Image Display </b>: {details.isSharingImages ? "SHOW" : "NOT SHOW"}
                        </Typography>
                        <Typography sx={{
                            width: "33%",
                        }} variant="body1" color="textSecondary" component="p" >
                            <b> Sender</b>: {details.firstname ? details.firstname + " " + details.lastname : details.email}
                        </Typography>
                        <Typography sx={{
                            width: "33%",
                        }} variant="body1" color="textSecondary" component="p" >
                            <b> Expire </b>: {details.expiresAt ? moment(details.expiresAt).format("YYYY-MM-DD  HH:mm:ss A") : " - "}
                        </Typography>
                    </Box>
                    {/* {details.isSharingImages && ( */}
                    <Box display={"flex"} className={classes.paper}>
                        {details.isSharingImages && (<Typography sx={{
                            width: "33%",
                        }} variant="body1" color="textSecondary" component="p" >
                            <b>Total Images</b>: {imagesList.length}
                        </Typography>)}
                        {details.type === "Bookmark" && (<Typography sx={{
                            width: "33%",
                        }} variant="body1" color="textSecondary" component="p" >
                            <b>Place</b>: {places.length}
                        </Typography>)}
                    </Box>
                    <Box className={classes.paper}>
                        <Divider></Divider>
                    </Box>

                    <Box sx={{ width: '80%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                {details.type === "Bookmark" && (<Tab label="Places" {...a11yProps(0)} />)}
                                {details.isSharingImages && (<Tab label="Gallery" {...a11yProps(1)} />)}
                            </Tabs>

                        </Box>
                        {details.type === "Bookmark" && (<TabPanel value={value} index={0}>
                            {!isLoader && places.length === 0 && (<Box justifyContent={"center"} className={classes.paper}>
                                <Typography textAlign={"center"} variant="h5" component="p" >
                                    No Data  </Typography>
                            </Box>)}
                            <Box 
                                sx={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "400px"
                                }}>
                                {!isLoader && places && places.length > 0 && <TimeLineMap chooseSelectData={chooseSelectData} visits={places} />}
                            </Box>
                        </TabPanel>)}
                        {details.isSharingImages && (<TabPanel value={value} index={1}>

                            {!isLoader && imagesList.length === 0 && (<Box justifyContent={"center"} className={classes.paper}>
                                <Typography textAlign={"center"} variant="h5" component="p" >
                                    No Data  </Typography>
                            </Box>)}

                            <ImageList sx={{ width: "100%", height: 540 }}>
                                <ImageListItem key="Subheader" cols={4}>
                                    {/* <ListSubheader component="div">December</ListSubheader> */}
                                </ImageListItem>
                                {imagesList.map((item, index) => (
                                    <ImageListItem key={item.image + "-" + index}>
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 233,
                                                width: 350,
                                                maxHeight: { xs: 233, md: 167 },
                                                maxWidth: { xs: 350, md: 250 },
                                            }}
                                            alt={item.image}
                                            src={`${new Buffer.from(item.image, 'base64').toString()}`}
                                        />
                                        {/* <img
                                src={`${new Buffer.from(item.image, 'base64').toString()}`}
                                srcSet={`${new Buffer.from(item.image, 'base64').toString()}`}
                                alt={item.image}
                                loading="lazy"
                                /> */}
                                        <ImageListItemBar
                                            sx={{
                                                width: 250,
                                                maxWidth: { xs: 350, md: 250 },
                                            }}
                                            //  sx={{
                                            //     height: 233,
                                            //     width: 350,
                                            //     maxHeight: { xs: 233, md: 167 },
                                            //     maxWidth: { xs: 350, md: 250 },
                                            // }}
                                            title={item.name}
                                            subtitle={item.name}
                                            actionIcon={
                                                <IconButton
                                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                    aria-label={`info about ${item.name}`}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            }
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </TabPanel>)}
                    </Box>
                </Item>
            </Grid>
        </Grid>
    </Box>);


}
export default SharedBookmarkorTrax;