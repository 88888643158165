import { useState, useEffect, useContext } from "react";
import "./Admin.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Share2 } from "react-feather";
import Button from "@mui/material/Button";
import moment from "moment";
import { InputBase, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import useAxios from "../../../helpers/Hooks/useAxios";
import { URLS } from "../../../helpers/URLS";
import { getUserData } from "../../../Utlities/utlities";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";

import { DialogBox } from "../../common/ModelDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Chip from "@mui/material/Chip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import LoaderAndToasterContext from "../../../Context/LoaderAndToaster";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import { Delete, Edit, LensTwoTone } from "@mui/icons-material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { X, Save } from "react-feather";
import Active from "../assets/active.png";
import InActive from "../assets/inactive.png";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import OrgModel from "./OrgModel";
import ApplicationModel from "./ApplicationModel";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const OrgAndAdmin = () => {
  const history = useHistory();
  const { messageAlert } = useContext(LoaderAndToasterContext);
  const { fetchData } = useAxios();
  const { id } = getUserData();
  const [searchInput, setSearchInput] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const handleChange = (event, newValue) => {
    setSearchInput("");
    setTabValue(newValue);
  };
  const getOrganization = async () => {
    const data = await fetchData({
      url: URLS.ADMIN_ORGANIZATIONS,
      method: "get",
      body: null,
      headersData: "",
      headerParameters: "",
    });
    if (data.status === 200) {
      setOrganizationList(data.data);
    }
    // console.log(data)
  };

  useEffect(() => {
    getOrganization();
  }, []);

  const selectOrg = (accordionId) => {
    if (expanded === accordionId) {
      setExpanded(false);
    } else {
      setExpanded(accordionId);
    }
  };
  const [modelPopup, setModelPopup] = useState(false);
  const [error, setError] = useState([]);
  const [userData, setUserData] = useState({
    isEdit: false,
    firstname: "",
    lastname: "",
    mobile: "",
    email: "",
    birthDate: null,
    image_url: "",
    gender: "male",
    role: "ADMIN",
    application: {
      name: "",
      _id: "",
    },
    organization: {
      name: "",
      _id: "",
      applications: [],
    },
  });
  const ReserForm = () => {
    setUserData({
      isEdit: false,
      firstname: "",
      lastname: "",
      mobile: "",
      email: "",
      birthDate: null,
      title: "Mr",
      image_url: "",
      gender: "male",
      role: "ADMIN",
      application: {
        name: "",
        _id: "",
      },
      organization: {
        name: "",
        _id: "",
        applications: [],
      },
    });
  };
  const onChangeHandler = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const modelHandle = () => {
    if (modelPopup) {
      ReserForm();
    }
    setError([]);
    setModelPopup(!modelPopup);
  };

  const [userList, setUserList] = useState([]);
  const [userMasterList, setMasterUserList] = useState([]);

  const getAllUsers = async () => {
    const data = await fetchData({
      url: URLS.getAllUsers,
      method: "get",
      body: null,
      headersData: "",
    });
    if (data.status === 200) {
      setUserList([...data.data]);
      setMasterUserList([...data.data]);
    }
  };
  useEffect(() => {
    if (id) {
      getAllUsers();
    }
  }, [id]);

  return (
    <>
      <div className="Adminview">
        <div className="Adminview-searchtab shadow">
          <Paper className="Adminview-search">
            <InputBase
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search organization"
              inputProps={{ "aria-label": "search google maps" }}
            />
            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <div className="Adminview-button">
            <div>
              <OrgModel
                isEdit={false}
                getOrganization={getOrganization}
                className="Adminview-btn"
              />
            </div>
            <div>
              <button className="Adminview-btn" onClick={modelHandle}>
                <PersonAddIcon className="m-1" />
                Add Admin
              </button>
            </div>
          </div>
          {/* Org */}

          {/* Org  */}

          {/* <Button variant="outlined" color="primary" startIcon={<FmdGoodIcon />} sx={{ float: 'right' }}>Location</Button>
                                <Button variant="contained" color="primary" startIcon={<GroupIcon />} sx={{ float: 'right' }}>All Users</Button> */}
        </div>

        <div className="card shadow Adminview-Table">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Admins"
                {...a11yProps(0)}
                className="Adminview-Tabs"
              />
              <Tab
                label="Organization"
                {...a11yProps(1)}
                className="Adminview-Tabs"
              />
            </Tabs>
          </Box>
          <TabPanel
            value={tabValue}
            index={0}
            className="Adminview-Table-container"
          >
            <Table aria-label="simple table">
              <TableHead className="Adminview-Table-header">
                <TableRow>
                  <TableCell className="Adminview-table-heading">
                    First Name
                  </TableCell>
                  <TableCell className="Adminview-table-heading">
                    Last Name
                  </TableCell>
                  <TableCell className="Adminview-table-heading">
                    Email
                  </TableCell>
                  <TableCell className="Adminview-table-heading">
                    Mobile
                  </TableCell>
                  <TableCell className="Adminview-table-heading">DOB</TableCell>
                  <TableCell className="Adminview-table-heading">
                    Gender
                  </TableCell>
                  <TableCell className="Adminview-table-heading">
                    Role
                  </TableCell>
                  <TableCell className="Adminview-table-heading">
                    Organization
                  </TableCell>
                  <TableCell className="Adminview-table-heading">
                    Application
                  </TableCell>
                  <TableCell className="Adminview-table-heading">
                    Status
                  </TableCell>
                  <TableCell className="Adminview-table-heading">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ fontStyle: "normal" }}>
                {userList.map((row) => (
                  <TableRow hover key={row.email}>
                    <TableCell>{row.firstname}</TableCell>
                    <TableCell>{row.lastname}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.mobile}</TableCell>
                    <TableCell>
                      {moment(row.birthDate).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell>{row.gender}</TableCell>
                    <TableCell>{row.role}</TableCell>
                    <TableCell>
                      {row.organization.length ? row.organization[0].name : "-"}
                    </TableCell>
                    <TableCell>
                      {row.application.length ? row.application[0].name : "-"}
                    </TableCell>
                    <TableCell align="center">
                      <Avatar
                        sx={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={async () => {
                          const data = await fetchData({
                            url: URLS.user,
                            method: "put",
                            body: {
                              activeStatus: !row.activeStatus,
                              email: row.email,
                            },
                            headersData: "",
                          });
                          if (data.status === 200) {
                            messageAlert(data.message, "success");
                            getAllUsers();
                          } else {
                            messageAlert(data.message, "error");
                          }
                        }}
                        alt={row.email}
                        src={row.activeStatus ? Active : InActive}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <EditIcon
                        style={{
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          let org = {
                            name: "",
                            _id: "",
                            applications: [],
                          };
                          if (row.organization.length) {
                            org = organizationList.find(
                              (item) => item._id === row.organization[0]._id
                            );
                          }
                          let application = {
                            name: "",
                            _id: "",
                          };
                          if (row.application.length) {
                            application = org.applications.find(
                              (item) => item._id === row.application[0]._id
                            );
                          }
                          setUserData({
                            isEdit: true,
                            ...row,
                            application: application,
                            organization: org,
                          });
                          modelHandle();
                        }}
                      />
                      <VisibilityIcon
                        onClick={() => history.push(`/UserTimeLine/${row._id}`)}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                      {/* <Chip icon={<Active />} label="Call me" /> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel
            value={tabValue}
            index={1}
            className="Adminview-Table-container"
          >
            <div>
              {organizationList.map((row) => (
                <Accordion
                  key={row._id}
                  expanded={expanded === row._id}
                  onChange={() => selectOrg(row._id)}
                >
                  <AccordionSummary
                    className="header-row"
                    expandIcon={<ExpandMoreIcon sx={{ color: "#ffff" }} />}
                    aria-controls="panel1bh-content"
                    id={row._id}
                  >
                    {/* <TableRow>
                      <TableCell className="Organizations-table-cell"> {row.name} </TableCell>
                      <TableCell className="Organizations-table-cell"> {row.email} </TableCell>
                      <TableCell className="Organizations-table-cell"> {row.website} </TableCell>
                      <TableCell className="Organizations-table-cell"> <EditIcon /> </TableCell>
                    </TableRow> */}
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      {row.name}
                    </Typography>
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      {row.email}
                    </Typography>
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      {row.website}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ m: 1 }}>
                      <div>
                        <Typography>
                          Applications
                          <div className="Adminview-button gap-2">
                            <ApplicationModel
                              isEdit={false}
                              getOrganization={getOrganization}
                              org={row}
                            />
                            <OrgModel
                              isEdit={true}
                              row={row}
                              getOrganization={getOrganization}
                            />
                          </div>
                        </Typography>
                      </div>
                      <div></div>
                    </Box>
                    <Table aria-label="simple table">
                      <TableHead className="Adminview-Table-header">
                        <TableRow>
                          <TableCell className="Adminview-table-heading">
                            Name
                          </TableCell>
                          <TableCell className="Adminview-table-heading">
                            Description
                          </TableCell>
                          <TableCell className="Adminview-table-heading">
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ fontStyle: "normal" }}>
                        {row.applications.map((sub_row) => (
                          <TableRow hover key={sub_row.email}>
                            <TableCell align="center">{sub_row.name}</TableCell>
                            <TableCell align="center">
                              {sub_row.description}
                            </TableCell>
                            <TableCell align="center">
                              <ApplicationModel
                                row={sub_row}
                                isEdit={true}
                                getOrganization={getOrganization}
                                org={row}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </TabPanel>
        </div>
        {/* Model start */}
        <DialogBox
          onClose={modelHandle}
          aria-labelledby="customized-dialog-title"
          open={modelPopup}
          // fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{
              backgroundColor: "#25478e",
              color: "#fff",
              padding: "10px",
            }}
            onClose={modelHandle}
          >
            <h4>Add User</h4>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              <Box
                component="div"
                sx={{
                  textAlign: "center",

                  "& .MuiFormControl-root": { m: 2 },
                  "& .MuiAutocomplete-root": { m: 1, width: "40ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <div className="Form-view">
                  <div className="d-flex">
                    <TextField
                      sx={{ m: 1, width: "40ch" }}
                      onChange={onChangeHandler}
                      value={userData.firstname}
                      error={error[0]}
                      helperText={error[0]}
                      name="firstname"
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                    />
                    <TextField
                      sx={{ m: 1, width: "40ch" }}
                      onChange={onChangeHandler}
                      name="lastname"
                      value={userData.lastname}
                      error={error[1]}
                      helperText={error[1]}
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                    />
                  </div>
                  <div className="d-flex">
                    <TextField
                      sx={{ m: 1, width: "40ch" }}
                      error={error[5]}
                      helperText={error[5]}
                      value={userData.email}
                      disabled={userData.isEdit}
                      name="email"
                      id="outlined-basic"
                      label="Email"
                      onChange={onChangeHandler}
                      variant="outlined"
                    />
                    <TextField
                      sx={{ m: 1, width: "40ch" }}
                      onChange={onChangeHandler}
                      error={error[2]}
                      helperText={error[2]}
                      name="mobile"
                      value={userData.mobile}
                      id="outlined-basic"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      label="Mobile Number"
                      variant="outlined"
                    />
                  </div>
                  <div className="d-flex">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={userData.organization}
                      options={organizationList}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setUserData({
                            ...userData,
                            ["application"]: { name: "", _id: "" },
                            ["organization"]: newValue,
                          });
                        } else {
                          setUserData({
                            ...userData,
                            ["application"]: { name: "", _id: "" },
                            ["organization"]: {
                              name: "",
                              _id: "",
                              applications: [],
                            },
                          });
                        }
                      }}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={error[6]}
                          helperText={error[6]}
                          label="Organization"
                        />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={userData.application}
                      options={userData.organization.applications}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          setUserData({
                            ...userData,
                            ["application"]: newValue,
                          });
                        } else {
                          setUserData({
                            ...userData,
                            ["application"]: { name: "", _id: "" },
                          });
                        }
                      }}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={error[7]}
                          helperText={error[7]}
                          label="application"
                        />
                      )}
                    />
                  </div>

                  <div className="d-flex">
                    <div className="DOBField">
                      <TextField
                        sx={{ m: 1, width: "30ch" }}
                        className="Field-Names"
                        onChange={onChangeHandler}
                        error={error[3]}
                        helperText={error[3]}
                        id="date"
                        name="birthDate"
                        label="Date of birth"
                        value={moment(userData.birthDate).format("YYYY-MM-DD")}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="GenderControl">
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Gender
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={userData.gender}
                          name="gender"
                          onChange={onChangeHandler}
                        >
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Female"
                          />
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Male"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </Box>
            </Typography>
          </DialogContent>
          <DialogActions
            style={{ backgroundColor: "#9bbde0", color: "#4a6ca1" }}
          >
            <Button
              style={{ backgroundColor: "red", color: "#fff" }}
              variant="contained"
              onClick={(e) => {
                modelHandle();
              }}
            >
              <X sx={{ fontSize: "25px" }} />
              &nbsp;Cancel
            </Button>

            <Button
              style={{
                backgroundColor: "green",
                color: "#fff",
                paddingLeft: "10px",
              }}
              onClick={async (e) => {
                const customError = [];
                const validateEmail = (email) => {
                  return String(email)
                    .toLowerCase()
                    .match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
                };
                if (!userData.email) {
                  customError[5] = "Email is required";
                }
                if (userData.email) {
                  if (!validateEmail(userData.email)) {
                    customError[5] = "Email is not valid";
                  }
                }
                if (!userData.firstname) {
                  customError[0] = "First Name is required";
                }
                if (!userData.lastname) {
                  customError[1] = "Last Name is required";
                }
                if (!userData.mobile) {
                  customError[2] = "Mobile is required";
                }
                if (userData.mobile) {
                  if (userData.mobile.length !== 10) {
                    customError[2] = "Mobile must be 10 digits";
                  }
                }
                if (!userData.birthDate) {
                  customError[3] = "Birth Date is required";
                }

                if (!userData.gender) {
                  customError[4] = "Gender is required";
                }

                if (!userData.organization.name) {
                  customError[6] = "organization is required";
                }
                if (!userData.application.name) {
                  customError[7] = "application is required";
                }
                setError(customError);
                if (customError.length) {
                  return null;
                } else {
                  setError([]);
                }

                if (userData.isEdit) {
                  const payload = {
                    ...userData,
                    // firstname: userData.firstname,
                    // lastname: userData.lastname,
                    // mobile: userData.mobile,
                    // email: userData.email,
                    // birthDate: userData.birthDate,
                    // image_url: "",
                    // gender: userData.,
                    // role: "ADMIN",
                    organization: userData.organization._id,
                    application: userData.application._id,
                  };
                  const data = await fetchData({
                    url: URLS.user,
                    method: "put",
                    body: { ...payload },
                    headersData: "",
                  });
                  if (data.status === 200) {
                    messageAlert(data.message, "success");
                    modelHandle();
                    getAllUsers();
                  } else {
                    messageAlert(data.message, "error");
                  }
                } else {
                  const payload = {
                    ...userData,
                    // firstname: userData.firstname,
                    // lastname: userData.lastname,
                    // mobile: userData.mobile,
                    // email: userData.email,
                    // birthDate: userData.birthDate,
                    // image_url: "",
                    // gender: userData.,
                    // role: "ADMIN",
                    organization: userData.organization._id,
                    application: userData.application._id,
                  };
                  const data = await fetchData({
                    url: URLS.ADMIN,
                    method: "post",
                    body: { ...payload },
                    headersData: "",
                  });
                  if (data.status === 200) {
                    messageAlert(data.message, "success");
                    modelHandle();
                    getAllUsers();
                  } else {
                    messageAlert(data.message, "error");
                  }
                }
              }}
            >
              <Save sx={{ fontSize: "25px" }} />
              &nbsp;
              {userData.isEdit ? "UPDATE" : "Save"}
            </Button>
          </DialogActions>
        </DialogBox>
        {/* model end */}
        {/* Add Org */}
        {/* Add ORG */}
      </div>
    </>
  );
};

export default OrgAndAdmin;
