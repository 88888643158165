import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './NewDashboard.css';
import location from '../../../Assets/Icons/location.png';
import Calendar from '../../../Assets/Icons/calendar.png';
import Travel from '../../../Assets/Icons/suitcase.png';
import Pin from '../../../Assets/Icons/Location-pin.png';
import { ArrowRightCircle, Settings } from 'react-feather';
import { getUserData } from '../../../Utlities/utlities';
import moment from 'moment';
import useAxios from '../../../helpers/Hooks/useAxios';
import { URLS } from "../../../helpers/URLS";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TimeLineMap from './TimeLineMap';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';




const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));


function NewDashboard() {
    const history = useHistory();
    const [palces, setPlaces] = useState([]);
    const { fetchData } = useAxios();
    const [visitData, setVisitData] = useState({});
    const { id } = getUserData();
    const [map, setMap] = useState([]);
    const [viewVist, setViewVist] = useState({
        locality: "",
        name: "",
        rating: "",
        sublocality: "",
        createdOn: "",
        entryTime: "",
        exitTime: "",
        note:""
    });

    const chooseSelectData = (data) => {
        if (data) {
            const { locality, name, rating, sublocality } = data.userPlace;
            setViewVist({
                locality, name, rating, sublocality,
                createdOn: data.createdOn,
                entryTime: data.entryTime,
                exitTime: data.exitTime,
                note: data.notes
                
            })
        }
    }


    useEffect(() => {
        if (visitData && visitData.visits) {
            chooseSelectData(visitData.visits[0])
            setMap(visitData.visits)
        }
    }, [visitData])

    const getTrax = async () => {
        const currentDate = moment().format('YYYY-MM-DD');
        let date = moment();
        date = date.subtract(120, 'days')
        const pastDate = date.format('YYYY-MM-DD');
        const headers = {
            'startdate': `${pastDate}`,
            'enddate': `${currentDate}`,
            'userid': `${id}`
        }
        try {
            const data = await fetchData({ url: URLS.getTrax, method: 'get', body: null, headersData: "", headerParameters: headers });
            setVisitData(data);
            const visitNames = data?.visits?.map(item => item.userPlace.name);
            // setMap(data.visits.slice(0, 15))
            setPlaces(visitNames);
        }
        catch (err) {
            console.log("ERROR", err);
        }


    }

    useEffect(() => {
        if (visitData && visitData.visits) {
            setMap(visitData?.visits?.slice(0, 15))
        }
    }, [visitData])

    useEffect(() => {
        getTrax()
    }, [])


    return (
        <>
            <div className='Dashboard'>
                <div className='d-flex'>
                    <div className=' tabs shadow d-flex' onClick={()=>{history.push('/timelines') }}>
                        <div>
                            <img src={location} className='Images' />
                        </div>
                        <div className='tabs-data'>
                            <h3>{visitData?.placesCount}</h3>
                            <p>Places</p>
                        </div>
                    </div>
                    <div className=' tabs shadow d-flex' onClick={()=>{history.push('/timelines') }}>
                        <div>
                            <img src={Calendar} className='Images' />
                        </div>
                        <div className='tabs-data'>
                            <h3>120</h3>
                            <p>Days</p>
                        </div>
                    </div>
                    <div className=' tabs shadow d-flex' onClick={()=>{history.push('/timelines') }}>
                        <div>
                            <img src={Travel} className='Images' />
                        </div>
                        <div className='tabs-data'>
                            <h3>{visitData?.visitsCount}</h3>
                            <p>Total Visits</p>
                        </div>
                    </div>
                    <div className='Navigate'>
                        <div>
                            <ArrowRightCircle className='icon'  onClick={()=>{history.push('/myinsights') }}/>
                            <p>My Insights</p>
                        </div>
                    </div>
                </div>


                {/* Visit View Design                */}
                <div>
                <div className='heading'>
                        <h5>Places You visited</h5>
                    </div>
                <div className='shadow Placescontentview card'>
                   
                    <div className='visitcontent'>
                        <ul className='map-list visitcontent-ul'>
                            {visitData?.visits?.slice(0, 15).map((item, index) => <li key={index} className='visitcontent-li'>
                                <div className='visitcontent-text' style={{
                                    width: "85px"
                                }}>
                                    {/* <p className='textName'> */}
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">{item.userPlace.name}</Typography>
                                                <Typography color="inherit">{moment(item.entryTime).format(" h:mm a")} to {moment(item.exitTime).format(" h:mm a")}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    >

                                        <p className='textName'> {item.userPlace.name.length > 8 ? item.userPlace.name.substring(0, 8) + "..." : item.userPlace.name}</p>
                                    </HtmlTooltip>

                                    {/* {item.userPlace.name.length > 8 ? item.userPlace.name.substring(0, 8) + "..." : item.userPlace.name} */}
                                    {/* {item.userPlace.name} */}
                                    {/* </p> */}
                                    <i><img src={Pin} className='pinImage' /></i>

                                </div>
                            </li>)}

                        </ul>
                    </div>
                </div>
                </div>

                {/* Map View Design */}
                <div className='shadow contentview  card'>
                    <div className='mapheading'>
                        <h5 >Explore All The Places You have Visits</h5>
                    </div>
                    <div className='mapcontent'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-md-8 left-side-map'>
                                    <div className='map-styles '>
                                        {/*  */}
                                        {map && map.length > 0 && <TimeLineMap chooseSelectData={chooseSelectData} visits={map} />}

                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='container-fluid'>
                                        <div>
                                            <p className='locationheading'>Location Details</p>
                                        </div>
                                        <div>
                                            <table>
                                                <tr>
                                                    <td className='LocationKeys'><p>Name:</p></td>
                                                    <td>{viewVist.name}</td>
                                                </tr>
                                                <tr>
                                                    <td className='LocationKeys'><p>Entry Date:</p></td>
                                                    <td>{moment(viewVist.entryTime).format("YYYY-MM-DD HH:SS a")}</td>
                                                </tr>
                                                <tr>
                                                    <td className='LocationKeys'><p>Address:</p></td>
                                                    <td><address>
                                                        {viewVist.locality}
                                                        {viewVist.sublocality}
                                                    </address></td>
                                                </tr>
                                                <tr>
                                                    <td className='LocationKeys'><p>Rating: </p></td>
                                                    <td>
                                                       <p>
                                                            <Rating
                                                                name="simple-controlled"
                                                                value={viewVist.rating}
                                                                // onChange={(event, newValue) => {
                                                                //     setValue(newValue);
                                                                // }}
                                                            />
                                                       
                                                        {/* {viewVist.rating} */}
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='LocationKeys'><p>Note:</p></td>
                                                    <td> {viewVist.note}</td>
                                                </tr>
                                                <tr>
                                                    <td><img></img></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewDashboard