import React, { useState, useEffect, useContext } from 'react';
import './Login.css'
import Image from '../../Assets/LoginImg.png';
import Logo from '../../Assets/keeptrax_logo_1.jpg';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { userDataStore } from '../../Utlities/utlities';
import { useHistory } from 'react-router-dom';
import LoaderAndToasterContext from '../../Context/LoaderAndToaster';
import useAxios from '../../helpers/Hooks/useAxios';
import { URLS } from "../../helpers/URLS";


function Login() {
  const history = useHistory();
  const { fetchData } = useAxios();
  const { setIsLoader, messageAlert, setIsAuth } = useContext(LoaderAndToasterContext);
  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: ""
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormErrors({
      email: "",
      password: ""
    })
    let errors = {
      email: "",
      password: ""
    };
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!user) {
      errors.email = "Please enter your email";
    } else if (!regex.test(user)) {
      errors.email = "Invalid email format";
    }
    if (!pwd) {
      errors.password = "Please enter your password";
    } else if (pwd.length < 4) {
      errors.password = "Password must be more than 4 characters";
    }
    if (errors.email || errors.password) {
      setFormErrors(errors);
      return
    }

    try {
      const resp = await fetchData({ url: URLS.login, method: 'post', body: null, headersData: user + ':' + pwd });
      if (!resp.code) {
        userDataStore(resp)
        history.push("/dashboard")
        setIsAuth(true);
      } else {
        messageAlert(resp.message, "error")
      }
    } catch (error) {
      messageAlert(error.message, "error")
    }

    // setIsLoader(true)
    // let url = `${baseURL}`;
    // var data = Base64.stringify(Utf8.parse(user + ':' + pwd));
    // var reqHeaders = {
    //   'Content-Type': 'application/json',
    //   'user-agent': 'APIClient',
    //   basic: data
    // }
    // var request = {
    //   headers: reqHeaders,
    //   method: 'POST',
    //   url: 'users/login'
    // }
    // axios.post(url, request).then((res) => {
    //   setIsLoader(false)
    //   if (!res.data.code) {
    //     userDataStore(res.data)
    //     history.push("/dashboard")
    //     setIsAuth(true);
    //   } else {
    //     messageAlert(res.data.message, "error")
    //   }
    // }

    // ).catch(error => {
    //   setIsLoader(false);
    //   messageAlert(error.message, "error")
    // });

  }

  useEffect((e) => {
    if (localStorage && localStorage.userData) {
      history.push('/dashboard')
    }
  }, []);


  return (
    <>
      <div className='container-fluid Login-Header'>
        <nav className='navbar  '>
          <Link to="" className='navbar-brand p-0'>
            <img className='logo Login-Image' src={Logo} alt="logo" />
          </Link>
        </nav>
      </div>
      <div className='container-fluid loginview'>
        <div className='Logincontainer'>
          <div className='login-img'>
            <img src={Image}></img>
          </div>
          <div className='Login-form'>
            {/* <div className='login-form mx-auto'> */}
            <div className='Form-view'>
              <form onSubmit={handleSubmit}>
                <div className='Contents'>
                  <h1 className='signin'>Sign In</h1></div>
                <div className='Contents'>
                  <TextField
                    className='Login-Input-fields'
                    id="outlined-basic"
                    placeholder='abc@email.com'
                    label="Email"
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    error={formErrors.email}
                    helperText={formErrors.email}
                    variant="outlined" /></div>
                <div className='Contents'>
                  <TextField
                    className='Login-Input-fields'
                    type='password'
                    id="outlined-basic"
                    label="Password"
                    error={formErrors.password}
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    helperText={formErrors.password}
                    data-toggle="password"
                    variant="outlined"
                  /></div>
                <div className='Contents'>
                  <p className='Login-forgotpass'>Forgot Your Password?</p></div>
                <div className='Contents'>
                  <button className='Login-btn ' onClick={handleSubmit()}>Login</button></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-view container-fluid'>
        <p className='footer-content p-1'>
          <a className='' href="/">Keeptraxinc.com</a>
          © Copyright Reserved
        </p>
      </div>
    </>
  )
}

export default Login