import React, { useEffect, useState } from 'react';
import useAxios from "../../../helpers/Hooks/useAxios";
import { URLS } from "../../../helpers/URLS";
import { getUserData } from "../../../Utlities/utlities";
import { Buffer } from 'buffer';
import { useParams } from "react-router-dom";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import './Visitview.css'
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Visitview = () => {
  const { fetchData } = useAxios();
  const { id } = getUserData();
  const { placeID } = useParams();
  const [isLoader, setIsLoader] = useState(true);
  const [imagesList, setImagesList] = useState([]);


  const GetImages = async () => {
    const imagesUrl = `${URLS.IMAGES.replace("@userId", id)}`;
    const data = await fetchData({
      url: imagesUrl,
      method: "get",
      body: null,
      headersData: "",
      headerParameters: "",
    });
    const images = data.filter(x => x.placeId === placeID)
    setImagesList(images)
    setIsLoader(false);


  }

  useEffect(() => {
    GetImages();
  }, [])

  return (
    <div className='Imagesview-View'>
      <div className='Imagesview-container'>
        <ImageList className='Imagesview-list'>
          <ImageListItem key="Subheader" cols={4}>
          </ImageListItem>
          {imagesList.map((item, index) => (
            <ImageListItem key={item.image + "-" + index}>
              <Box
                className='Imagesview-content'
                component="img"
                alt={item.image}
                src={`${new Buffer.from(item.image, 'base64').toString()}`}
            
              />
            </ImageListItem>
          ))}
        </ImageList>

      </div>
    </div>

  )
}

export default Visitview