import React, { useContext, useEffect } from 'react'
import Header from './Header/Header'
import Sidenav from './Sidenav/Sidenav'
import LoaderAndToasterContext from '../../Context/LoaderAndToaster';
import useAxios from '../../helpers/Hooks/useAxios';
import { URLS } from "../../helpers/URLS";
import { Link, useHistory } from 'react-router-dom';
import { getUserData, userProfile, getProfileData } from '../../Utlities/utlities';

function HeaderAndSideNav({children}) {
  const { innerHeight: height } = window;
  const history = useHistory();
  const { fetchData } = useAxios();
  const { setIsLoader, messageAlert, setCurrentUseData, currentUserData } = useContext(LoaderAndToasterContext);
  const { id } = getUserData();
  const GetProfile = async () => {
    const data = await fetchData({ url: URLS.profile.replace("@id", id), method: 'get', body: null, headersData: "" });
    setCurrentUseData(data)
    userProfile(JSON.stringify(data));
  }
  const userLogout = async () => {
    const data = await fetchData({ url: URLS.logout.replace("@id", id), method: 'delete', body: null, headersData: "" });
    if (data && data.logoutstatus) {
      localStorage.clear();
      history.push("/")
    }
    if (data && data.code) {
      messageAlert(data.message, "error")
    }
  }

  useEffect(() => {
    if (id) {
      GetProfile()
    }
  }, [id]);


  return (
    <>
      <div className='col-md-12'>
        <Header currentUserData={currentUserData} userLogout={userLogout} />
      </div>
      <div className='d-flex'>
        <div className='col-md-2 '><Sidenav currentUserData={currentUserData} /></div>
        <div className='col-md-10' style={{
        minHeight:height-50
    }}>
        {children}

        </div>
      </div>
    </>
  )
}

export default HeaderAndSideNav