
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';

const key = '2jsUH1rxG4XS5XVeabwJBP9K69TPSc';

export const userDataStore = (data) => {
    var ciphertext = AES.encrypt(JSON.stringify(data), key).toString();
    localStorage.setItem('userData', ciphertext)
};



export const userProfile = (profileData) => {
    var ciphertext = AES.encrypt(JSON.stringify(profileData), key).toString();
    localStorage.setItem('profileData', ciphertext)
};

export const getUserData = () => {
    const ciphertext = localStorage.getItem('userData');
    if (ciphertext) {
        let bytes = AES.decrypt(ciphertext, key);
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return { ...decryptedData }
    } else {
        // return { result: false, rolesAllowed: [], useremail: "" }
        return { "id": "", "firstname": "", "lastname": "", "createdOn": "", "email": "", "token": { "token": "" } }
    }

}

export const getProfileData = () => {
    const ciphertext1 = localStorage.getItem('profileData');
    if (ciphertext1) {
        let bytes = AES.decrypt(ciphertext1, key);
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (decryptedData) {
            return JSON.parse(decryptedData)
        }
        return { "firstname": "", "lastname": "", "gender": "", "birthDate": "", "email": "", "image_url": "" }
        // return{ ...decryptedData}
    } else {
        return { "firstname": "", "lastname": "", "gender": "", "birthDate": "", "email": "", "image_url": "" }
    }
}

const timeLineIcons = ["ico_timeline_airport.imageset",
    "ico_timeline_cafe.imageset",
    "ico_timeline_client.imageset",
    "ico_timeline_culture.imageset",
    "ico_timeline_entertainment.imageset",
    "ico_timeline_fitness.imageset",
    "ico_timeline_food.imageset",
    "ico_timeline_friends_house.imageset",
    "ico_timeline_gasstation.imageset",
    "ico_timeline_grocery.imageset",
    "ico_timeline_home.imageset",
    "ico_timeline_hospital.imageset",
    "ico_timeline_hotel.imageset",
    "ico_timeline_nightlife.imageset",
    "ico_timeline_office.imageset",
    "ico_timeline_others.imageset",
    "ico_timeline_outdoor.imageset",
    "ico_timeline_outdoor.imageset",
    "ico_timeline_poi.imageset",
    "ico_timeline_school.imageset",
    "ico_timeline_shopping.imageset",
    "ico_timeline_traffic.imageset",
    "ico_timeline_travel.imageset",
    "ico_timeline_worship.imageset"

]

const mapPinIcons = [
    "ico_mappin_airport.imageset",
    "ico_mappin_cafe.imageset",
    "ico_mappin_client.imageset",
    "ico_mappin_culture.imageset",
    "ico_mappin_entertainment.imageset",
    "ico_mappin_fitness.imageset",
    "ico_mappin_food.imageset",
    "ico_mappin_friends_house.imageset",
    "ico_mappin_gasstation.imageset",
    "ico_mappin_grocery.imageset",
    "ico_mappin_home.imageset",
    "ico_mappin_hospital.imageset",
    "ico_mappin_hotel.imageset",
    "ico_mappin_nightlife.imageset",
    "ico_mappin_office.imageset",
    "ico_mappin_others.imageset",
    "ico_mappin_outdoor.imageset",
    "ico_mappin_outdoor.imageset",
    "ico_mappin_poi.imageset",
    "ico_mappin_school.imageset",
    "ico_mappin_shopping.imageset",
    "ico_mappin_traffic.imageset",
    "ico_mappin_travel.imageset",
    "ico_mappin_worship.imageset"

]
export const timeLineImagePath = (internalCat) => {
    const url = timeLineIcons.find(x => {
        let response = ""
        internalCat.forEach(y => {
            if (y) {
                const str = y.split(" ").join("").toLowerCase()
                if (x.toLowerCase().includes(str)) {
                    response = x
                }
            }
        })
        return response
    })
    if (url) {
        const path = url.split(".");
        return `${url}/${path[0]}.png`;
    } else {
        return null;
    }
}

export const mapImagePath = (internalCat) => {
    const url = mapPinIcons.find(x => {
        let response = ""
        internalCat.forEach(y => {
            if (y) {
                const str = y.split(" ").join("").toLowerCase()
                if (x.toLowerCase().includes(str)) {
                    response = x
                }
            }
        })
        return response
    })
    if (url) {
        const path = url.split(".");
        return `${url}/${path[0]}.png`;
    } else {
        return null;
    }
}




