import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import useAxios from "../../../helpers/Hooks/useAxios";
import { URLS } from "../../../helpers/URLS";
import { getUserData } from "../../../Utlities/utlities";
import TextField from "@mui/material/TextField";

import { DialogBox } from "../../common/ModelDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoaderAndToasterContext from "../../../Context/LoaderAndToaster";
import { useHistory } from "react-router-dom";
import { X, Save } from "react-feather";
import moment from 'moment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Autocomplete from "@mui/material/Autocomplete";
import EditIcon from "@mui/icons-material/Edit";

const AddUser = ({
    teamsList,
    getTeam,
    user,
    icon=false
}) => {
  const history = useHistory();
  const { messageAlert } = useContext(LoaderAndToasterContext);
  const { fetchData } = useAxios();
  const { id } = getUserData();
  const [modelPopup, setModelPopup] = useState(false);
  const [error, setError] = useState([]);
  const [userData, setUserData] = React.useState({
    isEdit: false,
    firstname: "",
    lastname: "",
    mobile: "",
    email: "",
    team: teamsList[0],
    birthDate: null,
    title: "Mr",
    image_url: "",
    gender: "male",
    role: "USER",
  });
  const modelHandle = () => {
    if (modelPopup) {
      ReserForm();
    }
    setError([]);
    setModelPopup(!modelPopup);
  };

  const ReserForm = () => {
    setUserData({
      isEdit: false,
      firstname: "",
      lastname: "",
      mobile: "",
      email: "",
      team: teamsList[0],
      birthDate: null,
      title: "Mr",
      image_url: "",
      gender: "male",
      role: "USER",
    });
  };

  const onChangeHandler = (e) => {
    setUserData({
        ...userData,
        [e.target.name]: e.target.value
    })
}


  return (
    <>
    { icon === false && ( <Button
        onClick={modelHandle}
        variant="contained"
        color="primary"
        startIcon={<PersonAddIcon sx={{ fontSize: "25px" }} />}
        sx={{ float: "right" }}
      >
        Add User
      </Button>)} 
      { icon === true && (<EditIcon
          style={{
            marginRight: "10px",
            cursor: "pointer",
          }} onClick={() => {
            // console.log(user)
            setUserData({
                isEdit: true,
                ...user,
                team:teamsList.find(x=>x._id === user.team_id)
            })
            modelHandle();

          }}
          
          />
      )}
      <DialogBox
        onClose={modelHandle}
        aria-labelledby="customized-dialog-title"
        open={modelPopup}
        // fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{ backgroundColor: "#25478e", color: "#fff", padding: "10px" }}
          onClose={modelHandle}
        >
          <h4>User</h4>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Box
              component="div"
              sx={{
                textAlign: "center",

                "& .MuiFormControl-root": { m: 2 },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="Form-view">
                <div className="d-flex">
                  <TextField
                    sx={{ m: 1, width: "40ch" }}
                    onChange={onChangeHandler}
                    value={userData.firstname}
                    error={error[0]}
                    helperText={error[0]}
                    name="firstname"
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                  />
                  <TextField
                    sx={{ m: 1, width: "40ch" }}
                    onChange={onChangeHandler}
                    name="lastname"
                    value={userData.lastname}
                    error={error[1]}
                    helperText={error[1]}
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                  />
                </div>
                <div className="d-flex">
                  <TextField
                    sx={{ m: 1, width: "40ch" }}
                    error={error[5]}
                    helperText={error[5]}
                    value={userData.email}
                    disabled={userData.isEdit}
                    name="email"
                    id="outlined-basic"
                    label="Email"
                    onChange={onChangeHandler}
                    variant="outlined"
                  />
                  <TextField
                    sx={{ m: 1, width: "40ch" }}
                    onChange={onChangeHandler}
                    error={error[2]}
                    helperText={error[2]}
                    name="mobile"
                    value={userData.mobile}
                    id="outlined-basic"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    label="Mobile Number"
                    variant="outlined"
                  />
                      <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={userData.team}
                    options={teamsList}
                    getOptionLabel={(option) => option.name}
                    onChange={(e,newValue)=>{
                        if(newValue){
                            setUserData({
                                ...userData,
                                ["team"]: newValue
                            }) 
                        } else {
                            setUserData({
                                ...userData,
                                ["team"]: {
                                    name:"",
                                    _id:""
                                }
                            }) 
                        }

                    }}
                  
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={error[6]}
                        helperText={error[6]}
                        label="Team"
                      />
                    )}
                  />
                </div>

                <div className="d-flex">
                  <div className="DOBField">
                    <TextField
                      sx={{ m: 1, width: "30ch" }}
                      className="Field-Names"
                      onChange={onChangeHandler}
                      error={error[3]}
                      helperText={error[3]}
                      id="date"
                      name="birthDate"
                      label="Date of birth"
                      value={moment(userData.birthDate).format("YYYY-MM-DD")}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="RoleField">
                    <FormControl sx={{ m: 1, width: "20ch" }}>
                      <InputLabel id="demo-simple-select-label">
                        Role
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={userData.role}
                        label="Role"
                        name="role"
                        onChange={onChangeHandler}
                      >
                        <MenuItem value={"USER"}>USER</MenuItem>
                        <MenuItem value={"MANAGER"}>MANAGER</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
              
                  <div className="GenderControl">
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Gender
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={userData.gender}
                        name="gender"
                        onChange={onChangeHandler}
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Male"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                {/* <FormControl width={40}>
                <InputLabel id="demo-simple-select-label">Title</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userData.title}
                    label="Title"
                    name="title"
                    onChange={onChangeHandler}
                >
                    <MenuItem value={"Mr"}>Mr</MenuItem>
                    <MenuItem value={"Ms"}>Ms</MenuItem>
                    <MenuItem value={"Mrs"}>Mrs</MenuItem>
                </Select>
            </FormControl> */}
              </div>
            </Box>
          </Typography>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#9bbde0", color: "#4a6ca1" }}>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            variant="contained"
            onClick={(e) => {
              modelHandle();
            }}
          >
            <X sx={{ fontSize: "25px" }} />
            &nbsp;Cancel
          </Button>

          <Button
            style={{
              backgroundColor: "green",
              color: "#fff",
              paddingLeft: "10px",
            }}
            onClick={async (e) => {
              const customError = [];
              const validateEmail = (email) => {
                return String(email)
                  .toLowerCase()
                  .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  );
              };
              if (!userData.email) {
                customError[5] = "Email is required";
              }
              if (userData.email) {
                if (!validateEmail(userData.email)) {
                  customError[5] = "Email is not valid";
                }
              }
              if (!userData.firstname) {
                customError[0] = "First Name is required";
              }
              if (!userData.lastname) {
                customError[1] = "Last Name is required";
              }
              if (!userData.mobile) {
                customError[2] = "Mobile is required";
              }
              if (userData.mobile) {
                if (userData.mobile.length !== 10) {
                  customError[2] = "Mobile must be 10 digits";
                }
              }
              if (!userData.birthDate) {
                customError[3] = "Birth Date is required";
              }

              if (!userData.gender) {
                customError[4] = "Gender is required";
              }
              if (!userData.team.name) {
                customError[6] = "Team is required";
              }
              setError(customError);
              if (customError.length) {
                return null;
              } else {
                setError([]);
              }

              if (userData.isEdit) {
                const data = await fetchData({
                  url: URLS.user,
                  method: "put",
                  body: { ...userData,
                    team_id:userData.team._id },
                  headersData: "",
                });
                if (data.status === 200) {
                  messageAlert(data.message, "success");
                  modelHandle();
                  getTeam();
                } else {
                  messageAlert(data.message, "error");
                }
              } else {
                const data = await fetchData({
                  url: URLS.user,
                  method: "post",
                  body: { ...userData,
                    team_id:userData.team._id
                 },
                  headersData: "",
                });
                if (data.code) {
                  messageAlert(data.message[0], "error");
                } else {
                  messageAlert(data.message, "success");
                  getTeam();
                  modelHandle();
                }
              }
            }}
          >
            <Save sx={{ fontSize: "25px" }} />
            &nbsp;
            {userData.isEdit ? "UPDATE" : "Save"}
          </Button>

          {/* <Button
    style={{ backgroundColor: '#394671', color: '#fff', border: '1px solid' }}
>
    Close
</Button> */}
        </DialogActions>
      </DialogBox>
    </>
  );
};

export default AddUser;
