import React from 'react'
import './TraxShare.css'

function TraxShare() {
    return (
        <div className='SharedTrax-view'>
            <div>
                <p className='heading-trax'>Share your Trax</p>
                <hr className='Traxline'></hr>
            </div>
            <div>
                
            </div>
        </div>
    )
}

export default TraxShare