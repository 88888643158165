import React, { useContext, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles'
import useAxios from '../../../helpers/Hooks/useAxios';
import { URLS } from "../../../helpers/URLS";
import { getUserData } from '../../../Utlities/utlities';
import LoaderAndToasterContext from '../../../Context/LoaderAndToaster';
import { useParams } from "react-router-dom";
import { Buffer } from 'buffer';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TimeLineMap from '../Dashboard/TimeLineMap';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles({
    paper: {
        padding: "8px",
        textAlign: 'left',
        fontWeight: "fontWeightBold",
        fontSize: "24px",
    },
});


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const BookmarkView = () => {
    const classes = useStyles();
    const [isLoader, setIsLoader] = useState(true);
    const { fetchData } = useAxios();
    const { id } = getUserData();
    const { messageAlert } = useContext(LoaderAndToasterContext);
    const { bookmarkid } = useParams();
    const [imagesList, setImagesList] = useState([]);
    const [places, setPlaces] = useState([]);
    const [details, setDetails] = useState({
        startTime: "",
        endTime: "",
        id: "",
        name: ''
    })
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const getBookMarksList = async () => {
        const data = await fetchData({ url: URLS.BOOKMARK.replace("@userId", id), method: 'get', body: null, headersData: "", headerParameters: "" });
        const bookmarkData = data.bookmarks.find(bookmark => bookmark.id === bookmarkid);
        setDetails({
            startTime: moment(bookmarkData.startTime).format('YYYY-MM-DD'),
            endTime: moment(bookmarkData.endTime).format('YYYY-MM-DD'),
            id: bookmarkData.id,
            name: bookmarkData.name
        })

        // console.log(bookmarkData);

        const startTime = moment(bookmarkData.startTime).unix() * 1000;
        const endTime = moment(bookmarkData.endTime).unix() * 1000;

        let UrlBuild = `${URLS.IMAGES.replace("@userId", id)}?startTime=${startTime}&endTime=${endTime}`;

        const Images = await fetchData({ url: UrlBuild, method: 'get', body: null, headersData: "", headerParameters: "" });
        if (!Images.length) {
            setIsLoader(false);
            return null;
        }
        setImagesList(Images)
        const placesIds = Images.map(image => image.placeId).join(",");
        let placesUrlBuild = `${URLS.PLACES.replace("@userId", id)}?placeIds=${placesIds}`;
        const PlacesList = await fetchData({ url: placesUrlBuild, method: 'get', body: null, headersData: "", headerParameters: "" });
        // console.log("PlacesList",PlacesList)
        const p = PlacesList.places.map(x => {
            return {
                userPlace: {
                    name: x.name,
                    loc: {
                        coordinates: [Number(x.loc.split(",")[1]), Number(x.loc.split(",")[0])]
                    }
                }
            }
        })
        setPlaces([...p])
        setIsLoader(false)


    }

    useEffect(() => {
        getBookMarksList();
    }, [])

    const chooseSelectData = (data) => {
    }
    return (
        <>
            <div className='col-md-12 Bookmarkview'>
                {/* Loader */}
                <Backdrop
                    sx={{ color: '#fff', zIndex: 9999 }}
                    open={isLoader}
                >
                    <CircularProgress color="inherit" />

                </Backdrop>
                {/* Loader */}
                <div className='Bookmarkview-tab'>
                    {/* <div className='d-flex'>
                        <div className='w-30'> <th>Name</th>:<td>{details.name}</td> </div>
                        <div className='w-30'> <th>From Date</th><td>{details.name}</td> </div>
                        <div className='w-30'> <th>Name</th><td>{details.name}</td> </div>
                    </div> */}
                    <Item>
                        <Box display={"flex"} className={classes.paper}>
                            <Typography sx={{
                                width: "33%",
                            }} variant="body1" color="textSecondary" component="p" >
                                <b> Name </b>: {details.name}
                            </Typography>
                            <Typography sx={{
                                width: "33%",
                            }} variant="body1" color="textSecondary" component="p" >
                                <b> From Date </b>: {details.startTime}
                            </Typography>
                            <Typography sx={{
                                width: "33%",
                            }} variant="body1" color="textSecondary" component="p" >
                                <b> To Date </b>: {details.endTime}
                            </Typography>

                        </Box>
                        <Box display={"flex"} className={classes.paper}>
                            <Typography sx={{
                                width: "33%",
                            }} variant="body1" color="textSecondary" component="p" >
                                <b>Total Images</b>: {imagesList.length}
                            </Typography>
                            <Typography sx={{
                                width: "33%",
                            }} variant="body1" color="textSecondary" component="p" >
                                <b>Place</b>: {places.length}
                            </Typography>
                        </Box>


                        <Box className={classes.paper}>
                            <Divider></Divider>
                        </Box>

                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Places" {...a11yProps(0)} />
                                    <Tab label="Gallery" {...a11yProps(1)} />
                                </Tabs>

                            </Box>
                            <TabPanel value={value} index={0} className="Bookmarkview-Image-container">
                                {!isLoader && places.length === 0 && (<Box justifyContent={"center"} className={classes.paper}>
                                    <Typography textAlign={"center"} variant="h5" component="p" >
                                        No Data  </Typography>
                                </Box>)}
                                <Box className='mapshow'
                                    sx={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        height: "400px"
                                    }}>
                                    {!isLoader && places && places.length > 0 && <TimeLineMap chooseSelectData={chooseSelectData} visits={places} />}
                                </Box>
                            </TabPanel>
                            <TabPanel
                                className="Bookmarkview-Image-container"
                                value={value}
                                index={1}
                            >

                                {!isLoader && imagesList.length === 0 && (<Box justifyContent={"center"} className={classes.paper}>
                                    <Typography textAlign={"center"} variant="h5" component="p" >
                                        No Data  </Typography>
                                </Box>)}
                                <div className='Imagesview-View'>
                                    <div className='Imagesview-container'>
                                        <ImageList className='BookmarkImgview-list'>
                                            <ImageListItem key="Subheader" cols={4}>
                                            </ImageListItem>
                                            {imagesList.map((item, index) => (
                                                <ImageListItem key={item.image + "-" + index}>
                                                    <Box
                                                        className='Imagesview-content'
                                                        component="img"
                                                        alt={item.image}
                                                        src={`${new Buffer.from(item.image, 'base64').toString()}`}

                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>

                                    </div>
                                </div>


                            </TabPanel>
                        </Box>
                    </Item>

                </div>

            </div>

        </>


    )
}

export default BookmarkView;