
import { useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader, InfoWindow, } from '@react-google-maps/api';
import { Box, Typography } from '@mui/material';
import { mapImagePath } from "../../../Utlities/utlities";
const containerStyle = {
    width: '100%',
    height: '100%',
};

const TimeLineMap = ({ visits, chooseSelectData }) => {

    const [viewWindow, setViewWindow] = useState(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBns3FXr8mz5KFk2q7XTX6u1kHa87T3qgQ"
    })

    // console.log("Visits",visits,isLoaded);
    return <div className='mapview '>
        {isLoaded}
        {isLoaded && visits && visits.length && (<GoogleMap
            mapContainerStyle={containerStyle}
            center={{
                lat: visits[1].userPlace.loc.coordinates[1],
                lng: visits[1].userPlace.loc.coordinates[0]
            }}
            
            zoom={15}
            
        >
            {
                visits.map((item, index) => {
                    return (
                        
                            mapImagePath(item.userPlace.internalCat) && (
                                <Marker key={index}
                                    onMouseOver={() => setViewWindow(index)}
                                    onMouseOut={() => setViewWindow(null)}
                                    icon={{
                                        url: require(`../assets/mapPinImg/mapPinImg/${mapImagePath(
                                            item.userPlace.internalCat
                                        )}`)
                                    }}
                                    onClick={() => {
                                        chooseSelectData(item)
                                        
                                    }}
                                    position={{
                                        lat: item.userPlace.loc.coordinates[1],
                                        lng: item.userPlace.loc.coordinates[0]
                                    }} >

                                    {viewWindow === index && (<InfoWindow>
                                        <Box>
                                            <Typography> {item?.userPlace?.name} </Typography>
                                        </Box>
                                    </InfoWindow>)}
                                </Marker>)
                        
                    )
                })
            },
            { /* Child components, such as markers, info windows, etc. */}

        </GoogleMap>)
        }
    </div >

}
export default TimeLineMap