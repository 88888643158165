
// export const baseURL='https://demo.keeptraxapp.com/api/v2.0.1';
export const baseURL='https://oqnj42bptk.execute-api.us-west-2.amazonaws.com/dev/';
export const newBaseURL = 'https://app.keeptraxapp.com/'
//export const baseURL='http://172.16.2.77:8082/api/v2.0.1';
//export const apiKey ='198c3120-8fea-11ec-8249-9b65187e0f9b';

export const apiKey =
"198c3120-8fea-11ec-8249-9b65187e0f9b"
export const secretKey =
"198c5830-8fea-11ec-8249-9b65187e0f9b"
  