import React, { useContext, useState } from 'react';
import './Myinsights.css';
import moment from 'moment';
import TextField from "@mui/material/TextField";

const Myinsights = () => {
  return (
    <>
    <div className='Myinsights-view'>
      <div className='heading-container'>
        <h3 className='Myinsights-heading'>My Insights</h3>
      </div>
      <div className='myinsights-selector shadow'>
        <button className='btn btn-outline-primary'>Past 7days</button>
        <button className='btn btn-outline-primary'>Past 30days</button>
        <button className='btn btn-outline-primary'>Bookmarks</button>
        <div className='date-pickers'>
          <TextField
            className='myinsights-textfields'
            id="date"
            name="startDate"
            // value={dateParameters.startDate}
            // onChange={onChangeHandler}
            label="From"
            type="date"
            sx={{ width: 180 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            className='myinsights-textfields'
            id="date-1"
            name="endDate"
            // value={dateParameters.endDate}
            // onChange={onChangeHandler}
            label="To"
            type="date"
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* <Button variant="contained" color="primary" sx={{ margin: 1 }}> Serach </Button> */}
        </div>
      </div>
      <div>
        <div></div>
        <div className='myinsights-graph'>

        </div>
      </div>

    </div>
    </>
    
  )
}

export default Myinsights