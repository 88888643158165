import React,{useEffect} from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './Components/Login/Login';
import Navbar from './Components/Navbar/Navbar';
// import Dashboard from './Components/Dashboard/Dashboard';
// import Timelines from './Components/Timelines/Timelines';
import AuthenticatedRoute from './AuthenticatedRoute';
// import Profile from './Components/Profile/Profile';
// import Manageusers from './Components/ManageUsers/Manageusers'
import HeaderAndSideNav from './View/NewHomepage/HeaderAndSideNav';
// import NewDashboard from './View/Dashboard/NewDashboard';
import NewDashboard from './View/NewHomepage/Dashboard/NewDashboard';
import Profile from './View/NewHomepage/Profile';
// import SharedTrax from './Components/Sharedtrax/Sharedtrax';
import Manageusers from './View/NewHomepage/ManagerUsers';
import UserTimeLine from './View/NewHomepage/ManagerUsers/UserTimeLine';
import TraxShare from './Components/Sharedtrax/TraxShare/TraxShare';
import Timelines from './View/NewHomepage/Timelines';
import SharedTrax from './View/NewHomepage/SharedTrax';
import SharedBookmarkorTrax from './View/NewHomepage/SharedTrax/SharedBookmarkorTrax';
import BookMark from './View/NewHomepage/BookMark';
import BookmarkView from './View/NewHomepage/BookMark/BookmarkView';
import Visitview  from './View/NewHomepage/Timelines/Visitview';
import MyInsights from "./View/NewHomepage/MyInsights/MyInsights"
import OrgAndAdmin  from './View/NewHomepage/OrgAndAdmin';
import Myinsights from './View/NewHomepage/MyInsights/MyInsights';
import TeamAndAdmin from './View/NewHomepage/TeamAndAdmin';


function App() {
  useEffect(()=>{
  console.log(window.location)

  },[])

  return (
    <div>
      <BrowserRouter>
        {/* <Navbar user={responseData} auth={auth}/> */}
        <Switch>
          <Route path='/' exact render={props => {
            return <Login />
            // return <Navbar> <Login /></Navbar>
          }} />

          <AuthenticatedRoute path='/dashboard'
            exact render={props => {
              return <HeaderAndSideNav> <NewDashboard /> </HeaderAndSideNav>
            }} />

          <AuthenticatedRoute path='/timelines'
            exact render={props => {
              return <HeaderAndSideNav> <Timelines /> </HeaderAndSideNav>
            }} />
            <AuthenticatedRoute path='/myinsights'
            exact render={props => {
              return <HeaderAndSideNav> <Myinsights /> </HeaderAndSideNav>
            }} />

          {/* old routing
           <AuthenticatedRoute path="/dashboard" exact render={props => {
            return <Navbar> <Dashboard /></Navbar>
          }} /> */}
          {/* <AuthenticatedRoute path="/timelines" exact render={props => {
            return <Navbar><Timelines {...props} /></Navbar>
          }} /> */}
          <AuthenticatedRoute path="/profile" exact render={props => {
            return <HeaderAndSideNav><Profile {...props} /></HeaderAndSideNav>
          }} />
          <AuthenticatedRoute path="/sharedtrax" exact render={props => {
            return <HeaderAndSideNav><SharedTrax {...props} /></HeaderAndSideNav>
          }} />
          <AuthenticatedRoute path="/manageusers" exact render={props => {
            return <HeaderAndSideNav><Manageusers {...props} /></HeaderAndSideNav>
          }} />
          <AuthenticatedRoute path="/bookmark" exact render={props => {
            return <HeaderAndSideNav><BookMark {...props} /></HeaderAndSideNav>
          }} />
          <AuthenticatedRoute path='/UserTimeLine/:userid'
            exact render={props => {
              return <HeaderAndSideNav> <UserTimeLine /> </HeaderAndSideNav>
            }} />
          <AuthenticatedRoute path='/shared/:sharedid'
            exact render={props => {
              return <HeaderAndSideNav> <SharedBookmarkorTrax /> </HeaderAndSideNav>
            }} />
          <AuthenticatedRoute path='/bookmark/:bookmarkid'
            exact render={props => {
              return <HeaderAndSideNav> <BookmarkView /> </HeaderAndSideNav>
            }} />
          <AuthenticatedRoute path='/traxshare'
            exact render={props => {
              return <HeaderAndSideNav> <TraxShare /> </HeaderAndSideNav>
            }} />
          <AuthenticatedRoute path='/visit/:placeID'
            exact render={props => {
              return <HeaderAndSideNav> <Visitview /> </HeaderAndSideNav>
            }} />
                   <AuthenticatedRoute path='/OrgAndAdmin'
            exact render={props => {
              return <HeaderAndSideNav> <OrgAndAdmin /> </HeaderAndSideNav>
            }} />
                   <AuthenticatedRoute path='/TeamAndAdmin'
            exact render={props => {
              return <HeaderAndSideNav> <TeamAndAdmin /> </HeaderAndSideNav>
            }} />


          {/* <Route path='/dashboard' element={<Dashboard />} exact/>
          <Route path = '/timelines' element={<Timelines/>}exact /> */}


          {/* <Route path='/NewLogin' element={NewLogin}
            exact render={props => {
              return <NewLogin />
            }} /> */}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;