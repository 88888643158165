import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import './Profilepage.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import useAxios from '../../../helpers/Hooks/useAxios';
import { URLS } from "../../../helpers/URLS";
import { getUserData, userProfile, getProfileData } from '../../../Utlities/utlities';
import { Buffer } from 'buffer';
import LoaderAndToasterContext from '../../../Context/LoaderAndToaster';
import moment from 'moment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
// import { createStyles, makeStyles } from '@mui/styles';

// const useStyles = makeStyles({
//     root: {
//       background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
//       border: 0,
//       borderRadius: 3,
//       boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
//       color: 'white',
//       height: 48,
//       padding: '0 30px',
//     },
//   });

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }} >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Profile = () => {
    // const classes = useStyles();

    const [value, setValue] = React.useState(0);
    const { fetchData } = useAxios();
    const { id } = getUserData();
    const { messageAlert, setCurrentUseData, currentUserData } = React.useContext(LoaderAndToasterContext);
    const [error, setError] = React.useState([]);
    const [userData, setUserData] = React.useState({
        isEdit: false,
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        birthDate: new Date(),
        image_url: "",
        gender: ""
    });


    const [updatePassword, setUpdatePassword] = React.useState({
        currentPassword: "",
        newPassword: "",
        reEnterPassword: ""
    })

    const handleChange = (event, newValue) => {
        setUpdatePassword({
            currentPassword: "",
            newPassword: "",
            reEnterPassword: ""
        })
        setUserData({
            ...currentUserData,
            isEdit: false
        })
        setError([])
        setValue(newValue);
    };

    const GetProfile = async () => {
        const data = await fetchData({ url: URLS.profile.replace("@id", id), method: 'get', body: null, headersData: "" });
        setCurrentUseData({
            ...data
        })
        setUserData({
            ...data,
            isEdit: false
        })

    }
    const onChangeHandler = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    const onChangePasswordHandler = (e) => {
        setUpdatePassword({
            ...updatePassword,
            [e.target.name]: e.target.value
        })
    }


    React.useEffect(() => {
        if (id) {
            GetProfile()
        }
    }, [id])
    const resetData = () => {
        setError([])
        setUserData({
            ...currentUserData,
            isEdit: false,
        })
    }

    const onFileChange = async event => {
        const formData = new FormData();
        formData.append(
            "image",
            event.target.files[0],
            event.target.files[0].name
        );
        const data = await fetchData({ url: URLS.profileImage.replace("@userid", id), method: 'post', body: formData, headersData: "" });
        if (data && data.success) {
            messageAlert("Profile update successfully", "success")
            setCurrentUseData({
                ...currentUserData,
                image_url: "aHR0cHM6Ly91cGxvYWQud2lraW1lZGlhLm9yZy93aWtpcGVkaWEvY29tbW9ucy9iL2IxL0xvYWRpbmdfaWNvbi5naWY/MjAxNTEwMjQwMzQ5MjE="
            })
            // 
            setTimeout(() => {
                window.location.reload()
            }, 2000)
            //   GetProfile()
            // window.location.reload()
        }
        else {
            if (data.code) {
                messageAlert(data.message, "error")
            }
        }

    };
    const [isHovered, setIsHovered] = React.useState(false);
    const handleHover = (e) => {
        setIsHovered(!isHovered);
    }

    return (
        <>
            <div className='ProfileView'>
                <div className='card shadow ProfileContent'>
                    <div className='Profileheading'>
                        <p>My Profile</p>
                    </div>
                    <div className='ProfileContainer'>
                        <div className='ProfileImageside'>
                            <div>
                                <h3 className='ProfileUsrname'>{userData.firstname}&nbsp;{userData.lastname}</h3>
                            </div>
                            <div className=''>
                                <img alt={currentUserData.image_url}
                                    src={currentUserData.image_url ? new Buffer.from(currentUserData.image_url, 'base64').toString() : ""}
                                    className='ProfileImage' />
                            </div>
                            <div>
                                <button onClick={onFileChange()} accept="image/*" name="photo" type="file">

                                <label htmlFor="contained-button-file">
                                        <IconButton color="primary" aria-label="upload picture" onMouseEnter={handleHover} onMouseLeave={handleHover} component="label">

                                            <input hidden accept="image/*" onChange={onFileChange} name="photo" type="file" />
                                            <PhotoCamera className={`${!isHovered ? 'upload-icon' : ''}`} style={{
                                                position: 'absolute'
                                            }} />
                                        </IconButton>
                                </label>
                                Upload New Photo</button>


                            </div>
                        </div>
                        <div>
                            <div>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                            <Tab label="Profile" {...a11yProps(0)} />
                                            <Tab label="Change Password" {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <Box
                                            component="form"
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div className='ProfileFields'>
                                                <TextField
                                                    className='TextFields'
                                                    value={userData.email}
                                                    name="email"
                                                    disabled={true}
                                                    id="outlined-basic"
                                                    label="Email"
                                                    variant="outlined" />
                                                <TextField
                                                    className='TextFields'
                                                    onChange={onChangeHandler}
                                                    value={userData.firstname}
                                                    disabled={!userData.isEdit}
                                                    error={error[0]}
                                                    helperText={error[0]}
                                                    name="firstname"
                                                    id="outlined-basic"
                                                    label="First Name"
                                                    variant="outlined" />
                                            </div>
                                            <div className='ProfileFields'>
                                                <TextField
                                                    className='TextFields'
                                                    onChange={onChangeHandler}
                                                    name='lastname'
                                                    value={userData.lastname}
                                                    disabled={!userData.isEdit}
                                                    error={error[1]}
                                                    helperText={error[1]}
                                                    id="outlined-basic"
                                                    label="Last Name"
                                                    variant="outlined" />
                                                <TextField
                                                    className='TextFields'
                                                    onChange={onChangeHandler}
                                                    error={error[2]}
                                                    helperText={error[2]}
                                                    name='mobile'
                                                    value={userData.mobile}
                                                    disabled={!userData.isEdit}
                                                    id="outlined-basic"
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    label="Mobile Number"
                                                    variant="outlined" />
                                            </div>
                                            <div className='ProfileFields'>
                                                <TextField
                                                    className='TextFields'
                                                    onChange={onChangeHandler}
                                                    error={error[3]}
                                                    helperText={error[3]}
                                                    id="date"
                                                    name='birthDate'
                                                    label="Date of birth"
                                                    disabled={!userData.isEdit}
                                                    value={moment(userData.birthDate).format("YYYY-MM-DD")}
                                                    type="date"
                                                    sx={{ width: 220 }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                <FormControl className='TextFields'>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                                                    <RadioGroup
                                                        disabled={!userData.isEdit}
                                                        row
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        value={userData.gender}
                                                        name="gender"
                                                        onChange={onChangeHandler}
                                                    >
                                                        <FormControlLabel value="female" disabled={!userData.isEdit} control={<Radio />} label="Female" />
                                                        <FormControlLabel value="male" disabled={!userData.isEdit} control={<Radio />} label="Male" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </Box>
                                        <Box display="flex" justifyContent="flex-end" sx={{
                                            '& > :not(style)': { m: 1 },
                                        }}>
                                            {!userData.isEdit && (<Button variant="contained" onClick={(e) => {
                                                setError([])
                                                setUserData({ ...currentUserData, isEdit: true })
                                            }}>Edit</Button>)}
                                            {userData.isEdit && (<Button variant="contained" onClick={resetData}>Cancel</Button>)}
                                            {userData.isEdit && (<Button variant="contained" onClick={(async e => {
                                                const customError = [];
                                                if (!userData.firstname) {
                                                    customError[0] = "First Name is required";
                                                }
                                                if (!userData.lastname) {
                                                    customError[1] = "Last Name is required";
                                                }
                                                if (!userData.mobile) {
                                                    customError[2] = "Mobile is required";
                                                }
                                                if (userData.mobile) {
                                                    if (userData.mobile.length !== 10) {
                                                        customError[2] = "Mobile must be 10 digits";
                                                    }

                                                }
                                                if (!userData.birthDate) {
                                                    customError[3] = "Birth Date is required";
                                                }

                                                if (!userData.gender) {
                                                    customError[4] = "Gender is required"
                                                }
                                                setError(customError)
                                                if (customError.length) {
                                                    return null;
                                                }
                                                const data = await fetchData({ url: URLS.profile.replace("@id", id), method: 'put', body: { ...userData, image_url: currentUserData.image_url ? new Buffer.from(currentUserData.image_url, 'base64').toString() : "", birthDate: moment(userData.birthDate).format("MM/DD/YYYY") }, headersData: "" });
                                                if (data.code) {
                                                    messageAlert(data.message[0], "error")
                                                } else {
                                                    messageAlert(data.message, "success")
                                                    // setUserData({ ...userData, isEdit: false })
                                                    GetProfile();
                                                }

                                                // if (data && data.success) {
                                                //   window.location.reload()
                                                // }
                                            })}>Update</Button>)}
                                        </Box>

                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Box
                                            component="form"
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <TextField
                                                    className='TextFields'
                                                    type="password"
                                                    value={updatePassword.currentPassword}
                                                    onChange={onChangePasswordHandler}
                                                    name='currentPassword'
                                                    error={error[0]}
                                                    helperText={error[0]}
                                                    id="outlined-basic"
                                                    label="Current password"
                                                    variant="outlined" />
                                                <TextField
                                                    className='TextFields'
                                                    type="password"
                                                    value={updatePassword.newPassword}
                                                    onChange={onChangePasswordHandler}
                                                    name='newPassword'
                                                    error={error[1]}
                                                    helperText={error[1]}
                                                    id="outlined-basic"
                                                    label="New password"
                                                    variant="outlined" />
                                            </div>
                                            <div>
                                                <TextField
                                                    className='TextFields'
                                                    type="password"
                                                    value={updatePassword.reEnterPassword}
                                                    onChange={onChangePasswordHandler}
                                                    name='reEnterPassword' placeholder="Re-Enter password"
                                                    error={error[2]}
                                                    helperText={error[2]}
                                                    id="outlined-basic"
                                                    label="New password"
                                                    variant="outlined" />
                                            </div>

                                        </Box>
                                        <Box display="flex" justifyContent="flex-end" sx={{
                                            '& > :not(style)': { m: 1 },
                                        }}>
                                            <Button variant="contained" onClick={async (e) => {
                                                // setError([])
                                                const customError = [];
                                                if (updatePassword.currentPassword === "") {
                                                    customError[0] = "Current Password is required";
                                                }
                                                if (updatePassword.newPassword === "") {
                                                    customError[1] = "New Password is required";
                                                }
                                                if (updatePassword.reEnterPassword === "") {
                                                    customError[2] = "Re-Enter Password is required";
                                                }
                                                if (updatePassword.newPassword !== updatePassword.reEnterPassword) {
                                                    if (!customError[2]) {
                                                        customError[2] = "New Password and Re-Enter Password must be same"
                                                    }
                                                }
                                                if (updatePassword.newPassword.length < 8) {
                                                    if (!customError[1]) {
                                                        customError[1] = "Your password must be at least 8 characters";
                                                    }
                                                }
                                                if (updatePassword.newPassword.search(/[a-z]/i) < 0) {
                                                    if (!customError[1]) {
                                                        customError[1] = "Your password must contain at least one letter"
                                                    }
                                                }
                                                if (updatePassword.newPassword.search(/[0-9]/) < 0) {
                                                    if (!customError[1]) {
                                                        customError[1] = "Your password must contain at least one digit"
                                                    }
                                                }
                                                setError(customError)
                                                if (customError.length) {
                                                    return null;
                                                }

                                                const data = await fetchData({ url: URLS.updatePassword, method: 'put', body: { ...updatePassword }, headersData: "" });
                                                if (data && data.status && data.status === 400) {
                                                    messageAlert(data.message, "error")
                                                } else {
                                                    messageAlert(data.message, "success")
                                                    setUpdatePassword({
                                                        currentPassword: "",
                                                        newPassword: "",
                                                        reEnterPassword: ""
                                                    })
                                                }
                                            }}>Update</Button>
                                        </Box>
                                    </TabPanel>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Box sx={{ flexGrow: 1 }}>
                <Grid mt={2} container spacing={2}>
                    <Grid item xs={5}>
                        {isHovered}
                    </Grid>
                    <Grid item xs={4}>
                        <label htmlFor="contained-button-file">
                            <IconButton color="primary" aria-label="upload picture" onMouseEnter={handleHover} onMouseLeave={handleHover} component="label">
                                <Avatar
                                    alt={currentUserData.image_url}
                                    src={currentUserData.image_url ? new Buffer.from(currentUserData.image_url, 'base64').toString() : ""}
                                    sx={{ width: 100, height: 100 }}
                                />
                                <input hidden accept="image/*" onChange={onFileChange} name="photo" type="file" />
                                <PhotoCamera className={`${!isHovered ? 'upload-icon' : ''}`} style={{
                                    position: 'absolute'
                                }} />
                            </IconButton>
                        </label>
                        
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid ml={2} item xs={12}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Profile" {...a11yProps(0)} />
                                    <Tab label="Change Password" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '40ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        value={userData.email}
                                        name="email"
                                        disabled={true}
                                        id="outlined-basic"
                                        label="Email"
                                        variant="outlined" />
                                    <TextField
                                        onChange={onChangeHandler}
                                        value={userData.firstname}
                                        disabled={!userData.isEdit}
                                        error={error[0]}
                                        helperText={error[0]}
                                        name="firstname"
                                        id="outlined-basic"
                                        label="First Name"
                                        variant="outlined" />
                                    <TextField
                                        onChange={onChangeHandler}
                                        name='lastname'
                                        value={userData.lastname}
                                        disabled={!userData.isEdit}
                                        error={error[1]}
                                        helperText={error[1]}
                                        id="outlined-basic"
                                        label="Last Name"
                                        variant="outlined" />
                                    <TextField
                                        onChange={onChangeHandler}
                                        error={error[2]}
                                        helperText={error[2]}
                                        name='mobile'
                                        value={userData.mobile}
                                        disabled={!userData.isEdit}
                                        id="outlined-basic"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        label="Mobile Number"
                                        variant="outlined" />
                                    <TextField
                                        onChange={onChangeHandler}
                                        error={error[3]}
                                        helperText={error[3]}
                                        id="date"
                                        name='birthDate'
                                        label="Date of birth"
                                        disabled={!userData.isEdit}
                                        value={moment(userData.birthDate).format("YYYY-MM-DD")}
                                        type="date"
                                        sx={{ width: 220 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                                        <RadioGroup
                                            disabled={!userData.isEdit}
                                            row
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={userData.gender}
                                            name="gender"
                                            onChange={onChangeHandler}
                                        >
                                            <FormControlLabel value="female" disabled={!userData.isEdit} control={<Radio />} label="Female" />
                                            <FormControlLabel value="male" disabled={!userData.isEdit} control={<Radio />} label="Male" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                <Box display="flex" justifyContent="flex-end" sx={{
                                    '& > :not(style)': { m: 1 },
                                }}>
                                    {!userData.isEdit && (<Button variant="contained" onClick={(e) => {
                                        setError([])
                                        setUserData({ ...currentUserData, isEdit: true })
                                    }}>Edit</Button>)}
                                    {userData.isEdit && (<Button variant="contained" onClick={resetData}>Cancel</Button>)}
                                    {userData.isEdit && (<Button variant="contained" onClick={(async e => {
                                        const customError = [];
                                        if (!userData.firstname) {
                                            customError[0] = "First Name is required";
                                        }
                                        if (!userData.lastname) {
                                            customError[1] = "Last Name is required";
                                        }
                                        if (!userData.mobile) {
                                            customError[2] = "Mobile is required";
                                        }
                                        if (userData.mobile) {
                                            if (userData.mobile.length !== 10) {
                                                customError[2] = "Mobile must be 10 digits";
                                            }

                                        }
                                        if (!userData.birthDate) {
                                            customError[3] = "Birth Date is required";
                                        }

                                        if (!userData.gender) {
                                            customError[4] = "Gender is required"
                                        }
                                        setError(customError)
                                        if (customError.length) {
                                            return null;
                                        }
                                        const data = await fetchData({ url: URLS.profile.replace("@id", id), method: 'put', body: { ...userData, image_url: currentUserData.image_url ? new Buffer.from(currentUserData.image_url, 'base64').toString() : "", birthDate: moment(userData.birthDate).format("MM/DD/YYYY") }, headersData: "" });
                                        if (data.code) {
                                            messageAlert(data.message[0], "error")
                                        } else {
                                            messageAlert(data.message, "success")
                                            // setUserData({ ...userData, isEdit: false })
                                            GetProfile();
                                        }

                                        // if (data && data.success) {
                                        //   window.location.reload()
                                        // }
                                    })}>Update</Button>)}
                                </Box>

                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '40ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        type="password"
                                        value={updatePassword.currentPassword}
                                        onChange={onChangePasswordHandler}
                                        name='currentPassword'
                                        error={error[0]}
                                        helperText={error[0]}
                                        id="outlined-basic"
                                        label="Current password"
                                        variant="outlined" />
                                    <TextField
                                        type="password"
                                        value={updatePassword.newPassword}
                                        onChange={onChangePasswordHandler}
                                        name='newPassword'
                                        error={error[1]}
                                        helperText={error[1]}
                                        id="outlined-basic"
                                        label="New password"
                                        variant="outlined" />
                                    <TextField
                                        type="password"
                                        value={updatePassword.reEnterPassword}
                                        onChange={onChangePasswordHandler}
                                        name='reEnterPassword' placeholder="Re-Enter password"
                                        error={error[2]}
                                        helperText={error[2]}
                                        id="outlined-basic"
                                        label="New password"
                                        variant="outlined" />

                                </Box>
                                <Box display="flex" justifyContent="flex-end" sx={{
                                    '& > :not(style)': { m: 1 },
                                }}>
                                    <Button variant="contained" onClick={async (e) => {
                                        // setError([])
                                        const customError = [];
                                        if (updatePassword.currentPassword === "") {
                                            customError[0] = "Current Password is required";
                                        }
                                        if (updatePassword.newPassword === "") {
                                            customError[1] = "New Password is required";
                                        }
                                        if (updatePassword.reEnterPassword === "") {
                                            customError[2] = "Re-Enter Password is required";
                                        }
                                        if (updatePassword.newPassword !== updatePassword.reEnterPassword) {
                                            if (!customError[2]) {
                                                customError[2] = "New Password and Re-Enter Password must be same"
                                            }
                                        }
                                        if (updatePassword.newPassword.length < 8) {
                                            if (!customError[1]) {
                                                customError[1] = "Your password must be at least 8 characters";
                                            }
                                        }
                                        if (updatePassword.newPassword.search(/[a-z]/i) < 0) {
                                            if (!customError[1]) {
                                                customError[1] = "Your password must contain at least one letter"
                                            }
                                        }
                                        if (updatePassword.newPassword.search(/[0-9]/) < 0) {
                                            if (!customError[1]) {
                                                customError[1] = "Your password must contain at least one digit"
                                            }
                                        }
                                        setError(customError)
                                        if (customError.length) {
                                            return null;
                                        }

                                        const data = await fetchData({ url: URLS.updatePassword, method: 'put', body: { ...updatePassword }, headersData: "" });
                                        if (data && data.status && data.status === 400) {
                                            messageAlert(data.message, "error")
                                        } else {
                                            messageAlert(data.message, "success")
                                            setUpdatePassword({
                                                currentPassword: "",
                                                newPassword: "",
                                                reEnterPassword: ""
                                            })
                                        }
                                        // console.log("Hello My App:",data)


                                    }}>Update</Button>
                                </Box>
                            </TabPanel>
                        </Box>



                    </Grid>
                </Grid>
            </Box> */}
        </>

    )

}
export default Profile;