import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import useAxios from "../../../helpers/Hooks/useAxios";
import { URLS } from "../../../helpers/URLS";
import { getUserData } from "../../../Utlities/utlities";
import TextField from "@mui/material/TextField";

import { DialogBox } from "../../common/ModelDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoaderAndToasterContext from "../../../Context/LoaderAndToaster";
import { useHistory } from "react-router-dom";
import { X, Save } from "react-feather";
import EditIcon from "@mui/icons-material/Edit";

const ApplicationModel = ({getOrganization,org,isEdit,row}) => {
    const history = useHistory();
    const { messageAlert } = useContext(LoaderAndToasterContext);
    const { fetchData } = useAxios();
    const { id } = getUserData();
  const [modelPopup, setModelPopup] = useState(false);
  const [error, setError] = useState([]);
  const [payload, setPayload] = useState({
    name: "",
    description: ""
  });

  const ReserForm = () => {
    setPayload({
      name: "",
      description: "",
    });
  };

  const onChangeHandler = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    });
  };
  const modelHandle = () => {
    if (modelPopup) {
      ReserForm();
    }
    setError([]);
    setModelPopup(!modelPopup);
  };

  return (
    <>
     {isEdit === false && ( <Button
        onClick={modelHandle}
        variant="contained"
        color="primary"
        startIcon={<PersonAddIcon sx={{ fontSize: "25px" }} />}
        sx={{ float: "right" }}
      >
        Add Application
      </Button>)}
      {isEdit === true && (<EditIcon
          style={{
            marginRight: "10px",
            cursor: "pointer",
          }} onClick={() => {
            // console.log(user)
            setPayload({
              ...row
            })
            modelHandle();

          }}
          
          />

      )}
      <DialogBox
        onClose={modelHandle}
        aria-labelledby="customized-dialog-title"
        open={modelPopup}
        // fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{ backgroundColor: "#25478e", color: "#fff", padding: "10px" }}
          onClose={modelHandle}
        >
          <h4>{isEdit?"Edit":"Add"} Application</h4>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Box
              component="div"
              sx={{
                textAlign: "center",

                "& .MuiFormControl-root": { m: 2 },
                "& .MuiAutocomplete-root": { m: 1, width: "40ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="Form-view">
                <div className="d-flex">
                  <TextField
                    sx={{ m: 1, width: "40ch" }}
                    onChange={onChangeHandler}
                    value={payload.name}
                    error={error[0]}
                    helperText={error[0]}
                    name="name"
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                  />
                  <TextField
                    sx={{ m: 1, width: "40ch" }}
                    onChange={onChangeHandler}
                    name="description"
                    value={payload.description}
                    error={error[1]}
                    helperText={error[1]}
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                  />
                </div>
               
              </div>
            </Box>
          </Typography>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#9bbde0", color: "#4a6ca1" }}>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            variant="contained"
            onClick={(e) => {
              modelHandle();
            }}
          >
            <X sx={{ fontSize: "25px" }} />
            &nbsp;Cancel
          </Button>

          <Button onClick={async(e)=>{
                     const customError = [];
               
                     if (!payload.name) {
                        customError[0] = "Name is required";
                      }
                      if (!payload.description) {
                        customError[1] = "Description is required";
                      }
                      setError(customError);
                      if (customError.length) {
                        return null;
                      } else {
                        setError([]);
                      }
                      if(isEdit){
                        const data = await fetchData({
                          url: `${URLS.UPDATE_APPLICATION}/${row._id}`,
                          method: "put",
                          body: { ...payload },
                          headersData: "",
                        });
                        if (data.code) {
                          messageAlert(data.message[0], "error")
                      } else {
                          messageAlert(data.message, "success")
                          modelHandle();
                          getOrganization()
                      }
                      }else{
                      const data = await fetchData({
                        url: `${URLS.ORGANIZATIONS}/${org._id}/applications`,
                        method: "post",
                        body: { ...payload },
                        headersData: "",
                      });
                      if (data.code) {
                        messageAlert(data.message[0], "error")
                    } else {
                        messageAlert(data.message, "success")
                        modelHandle();
                        getOrganization()
                    }
                  }

          }}
            style={{
              backgroundColor: "green",
              color: "#fff",
              paddingLeft: "10px",
            }}
          >
            <Save sx={{ fontSize: "25px" }} />
            &nbsp; {isEdit?"Update":"Save"}
          </Button>
        </DialogActions>
      </DialogBox>
    </>
  );
};

export default ApplicationModel;
