import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { LoaderAndToasterProvider } from './Context/LoaderAndToaster';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StyledEngineProvider } from '@mui/material';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <LoaderAndToasterProvider>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </LoaderAndToasterProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

