const oldServer = '/api/v2.0.1/';
export const URLS = {
    categories: oldServer + 'data',
    login:`${oldServer}users/login`,
    profile:`${oldServer}users/@id/profile`,
    logout:`${oldServer}users/@id/logout`,
    profileImage:`${oldServer}users/@userid/pictures`,
    getTrax:`/get/trax`,
    updatePassword:`updatePassword`,
    // user Manager
    user:`user`,
    getAllUsers:`getAllUsers`,
    // shared trax
    GET_SHARED_TRAX:`${oldServer}users/@userid/shares`,
    SHARE: `${oldServer}users/@userId/shares`,
    GET_SHARE:`${oldServer}users/@userId/shares/@shareId`,
    GET_BOOKMARK_DATA:`${oldServer}users/@userId/shares/@shareId/bookmarks`,
    //BOOKMARK
    BOOKMARK:`${oldServer}users/@userId/bookmarks`,
    BOOKMARK_DELETE:`${oldServer}users/@senderId/shares/@shareId/`, // images or places
    IMAGES:`${oldServer}users/@userId/images`,
    PLACES:`${oldServer}users/@userId/places`,
    VISIT:`${oldServer}users/@userId/trax`,
    MYINSIGHTS:`${oldServer}users/@userId/analytics`,
    ORGANIZATIONS:`${oldServer}/organizations`,
    UPDATE_ORGANIZATIONS:`organizations/`,
    UPDATE_APPLICATION:`/applications/`,
    ADMIN_ORGANIZATIONS:`/getAllOrganizations`,
    ADMIN:`/addAdmin`,
    TEAM:`/teams`,
    UPDATE_TEAMS:`/teams/`,
    GET_MANAGER_USER:`/getManagerUser`
}

// "proxy": "https://app.keeptraxapp.com" - server
// "proxy": "http://localhost" - local
