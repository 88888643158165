import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Share2 } from "react-feather";
import Button from "@mui/material/Button";
import moment from "moment";
import { InputBase, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import useAxios from "../../../helpers/Hooks/useAxios";
import { URLS } from "../../../helpers/URLS";
import { getUserData } from "../../../Utlities/utlities";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";

import { DialogBox } from "../../common/ModelDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Chip from "@mui/material/Chip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import LoaderAndToasterContext from "../../../Context/LoaderAndToaster";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import { Delete, Edit, LensTwoTone } from "@mui/icons-material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { X, Save } from "react-feather";
import Active from "../assets/active.png";
import InActive from "../assets/inactive.png";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import TeamModel from "./TeamModel";
import AddUser from "./AddUser";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const TeamAndAdmin = () => {
  const history = useHistory();
  const { messageAlert } = useContext(LoaderAndToasterContext);
  const { fetchData } = useAxios();
  const { id } = getUserData();
  const [searchInput, setSearchInput] = useState("");
  const [teamsList, setTeamsList] = useState([]);

  const getTeam = async () => {
    const data = await fetchData({
      url: URLS.TEAM,
      method: "get",
      body: null,
      headersData: "",
    });
    if (data.status === 200) {
      setTeamsList(data.data);
    }
  };
  useEffect(() => {
    getTeam();
  }, []);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => {
    setExpanded(panel);
  };

  return (
    <div>
      <Box m={2} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item xs={{ p: 0, m: 0 }}>
              <Grid container>
                <Grid item xs={8}>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: 400,
                    }}
                  >
                    <InputBase
                      onChange={(e) => setSearchInput(e.target.value)}
                      value={searchInput}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search user"
                      inputProps={{ "aria-label": "search google maps" }}
                    />
                    <IconButton
                      type="submit"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      display: "flex",
                      "& > :not(style)": { m: 1 },
                    }}
                  >
                    <TeamModel isEdit={false} getTeam={getTeam} />
                    <AddUser getTeam={getTeam} icon={false} teamsList={teamsList} />
                  </Box>
                </Grid>
              </Grid>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item xs={{ p: 0, m: 0 }}>
              <div>
                {teamsList.map((team_row) => (
                  <Accordion
                    key={team_row._id}
                    expanded={expanded === team_row._id}
                    onChange={(e) => handleChange(team_row._id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={team_row._id}
                      id={team_row._id}
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        {team_row.name}
                      </Typography>
                      <Typography sx={{ color: "text.secondary" }}>
                        {team_row.description}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <div className="mb-5">
                    <TeamModel isEdit={true} getTeam={getTeam} row={team_row} />
                    </div>
                    <Table
                  sx={{ minWidth: 650, m: 0, p: 0 }}
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#e0e0e0",
                    }}
                  >
                    <TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          color: "#5856a9",
                          fontStyle: "normal",
                          fontSize: "17px",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Mobile</TableCell>
                      <TableCell>Date of Birth</TableCell>
                      <TableCell>Gender</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ fontStyle: "normal" }}>
                    {team_row.users.map((row) => (
                      <TableRow hover key={row.email}>
                        <TableCell>{row.firstname}</TableCell>
                        <TableCell>{row.lastname}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.mobile}</TableCell>
                        <TableCell>
                          {moment(row.birthDate).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell>{row.gender}</TableCell>
                        <TableCell>{row.role}</TableCell>
                        <TableCell align="center">
                          <Avatar
                            sx={{
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              const data = await fetchData({
                                url: URLS.user,
                                method: "put",
                                body: {
                                  activeStatus: !row.activeStatus,
                                  email: row.email,
                                },
                                headersData: "",
                              });
                              if (data.status === 200) {
                                messageAlert(data.message, "success");
                              } else {
                                messageAlert(data.message, "error");
                              }
                            }}
                            alt={row.email}
                            src={row.activeStatus ? Active : InActive}
                          />
                        </TableCell>
                        <TableCell align="center">
                        <AddUser getTeam={getTeam} user={row} icon={true} teamsList={teamsList} />
                          <VisibilityIcon
                            onClick={() =>
                              history.push(`/UserTimeLine/${row._id}`)
                            }
                            style={{
                              cursor: "pointer",
                            }}
                          />
                          {/* <Chip icon={<Active />} label="Call me" /> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default TeamAndAdmin;
