import React, { useContext, useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Edit3 ,Edit2 } from 'react-feather';
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import "./TimeL.css";
import TextField from "@mui/material/TextField";
import moment from "moment";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import useAxios from "../../../helpers/Hooks/useAxios";
import { URLS } from "../../../helpers/URLS";
import { getUserData, timeLineImagePath } from "../../../Utlities/utlities";
import PlaceIcon from "@mui/icons-material/Place";
import TimeLineMap from "../Dashboard/TimeLineMap";
import Avatar from "@mui/material/Avatar";
import NoData from "../assets/no_location_found_.webp";
import { bgcolor, height } from "@mui/system";
import EditLocation from "../EditLocation";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import { useHistory } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Timelines = () => {
  const history = useHistory();
  const [palces, setPlaces] = useState([]);
  const { fetchData } = useAxios();
  const [visitData, setVisitData] = useState({});
  const { id } = getUserData();
  const [map, setMap] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [isManager, setIsManager] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  let { userid } = {
    userid: id,
  };

  const [dateParameters, setDateParameters] = useState({
    startDate: moment().subtract(120, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const getTrax = async () => {
    
    setVisitData({});
    setIsLoaded(true);
    setSearchInput("");
    const currentDate = moment(dateParameters.endDate).format("YYYY-MM-DD");
    const pastDate = moment(dateParameters.startDate).format("YYYY-MM-DD");
    const headers = {
      startdate: `${pastDate}`,
      enddate: `${currentDate}`,
      userid: `${userid}`,
    };
    try {
      const data = await fetchData({
        url: URLS.getTrax,
        method: "get",
        body: null,
        headersData: "",
        headerParameters: headers,
      });
      setVisitData(data);
      const visitNames = data?.visits?.map((item) => item.userPlace.name);
      setPlaces(visitNames);
      setIsLoaded(false);
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const getCategory = async () => {
    try {
      const data = await fetchData({
        url: URLS.categories,
        method: "get",
        body: null,
        headersData: "",
        headerParameters: "",
      });
      setCategoriesList(data);

      // console.log("getCategory-data", data);
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const [viewVist, setViewVist] = useState({
    locality: "",
    name: "",
    rating: "",
    sublocality: "",
    createdOn: "",
    entryTime: "",
    exitTime: "",
  });

  const chooseSelectData = (data) => {
    if (data) {
      const { locality, name, rating, sublocality } = data.userPlace;
      setViewVist({
        locality,
        name,
        rating,
        sublocality,
        createdOn: data.createdOn,
        entryTime: data.entryTime,
        exitTime: data.exitTime,
      });
    }
  };
  useEffect(() => {
    getTrax();
  }, [dateParameters]);

  useEffect(() => {
    if (visitData && visitData.visits) {
      setMap(visitData.visits);
    }
  }, [visitData]);
  const onChangeHandler = (e) => {
    setDateParameters({
      ...dateParameters,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (searchInput) {
      if (visitData && visitData.visits) {
        const filteredData = visitData.visits.filter((item) =>
          item.userPlace.name.toLowerCase().includes(searchInput.toLowerCase())
        );
        setMap(filteredData);
      }
    } else {
      if (visitData && visitData.visits) {
        setMap(visitData.visits);
      }
      
    }
  }, [searchInput]);
  console.log(map);

  const [isOpen, setIsOpen] = useState(false);
  const modelHandler = () => {
    setIsOpen(!isOpen);
  };

  const [editLoactotionData, setEditLoactotionData] = useState({
    userID: "",
    placeID: "",
    locationID: "",
    type: "",
    data: {
      locality: "",
      name: "",
      rating: 0,
      sublocality: "",
      country: "",
      notes: "",
      administrative_area_level_1: "",
      internalCat: [],
    },
  });

  const EditLocationHandler = (data) => {
    setEditLoactotionData({
      userID: data.account,
      placeID: data.userPlace._id,
      locationID: data._id,
      type: "Location",
      data: {
        locality: data.userPlace.locality,
        name: data.userPlace.name,
        rating: data.userPlace.rating,
        sublocality: data.userPlace.sublocality,
        country: data.userPlace.country,
        notes: data.notes,
        administrative_area_level_1: data.userPlace.administrative_area_level_1,
        internalCat: data.userPlace.internalCat[0],
      },
    });
    console.log("address Data", {
      locality: data.userPlace.locality,
      name: data.userPlace.name,
      rating: data.userPlace.rating,
      sublocality: data.userPlace.sublocality,
      country: data.userPlace.country,
      administrative_area_level_1: data.userPlace.administrative_area_level_1,
      internalCat: data.userPlace.internalCat[0],
      notes: data.notes,
    });
    modelHandler();
  };

  const handleResponse = (status) => {
    if (status) {
      getTrax();
    }
    modelHandler();
  };

  return (
    <div className="Timelines-View">
      {/* Edit Loaction */}
      {isOpen && (
        <EditLocation
          handleResponse={handleResponse}
          isOpen={isOpen}
          categoriesList={categoriesList}
          setEditLoactotionData={setEditLoactotionData}
          editLoactotionData={editLoactotionData}
          modelHandler={modelHandler}
        />
      )} 
      {/* Edit Loaction */}
      <div className="Timelinesview-searchTab shadow">
        <div>
          <Paper className="Timelines-search-bar">
            <InputBase
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Location"
              inputProps={{ "aria-label": "search google maps" }}
            />
            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <div className="Timelines-datefilters">
          <TextField
            className="Timelines-Date"
            id="date"
            name="startDate"
            value={dateParameters.startDate}
            onChange={onChangeHandler}
            label="From"
            type="date"
            sx={{ width: 180 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            className="Timelines-Date"
            id="date-1"
            name="endDate"
            value={dateParameters.endDate}
            onChange={onChangeHandler}
            label="To"
            type="date"
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* <Button variant="contained" color="primary" sx={{ margin: 1 }}> Serach </Button> */}
        </div>
      </div>
      <div className="Timelinesview-visitcontainer">
        <div className="Timelinesview-Data shadow ">
          <Item>
            {!isLoaded && map.length === 0 && (
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  sx={{
                    textAlign: "center",
                    width: "200px",
                    height: "200px",
                  }}
                  alt={"No Dats"}
                  src={NoData}
                />
              </Box>
            )}
            {!isLoaded && map.length === 0 && (
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography>
                  <b>No Data</b>
                </Typography>
              </Box>
            )}

            <Timeline
              className="Timeline-visitsdata">
              {map?.map((item, index) => (
                <TimelineItem key={item._id}>
                  <TimelineOppositeContent >
                    <div className="TL-Loc-time">
                      <p className="TL-Loc-time-in">{moment(item.entryTime).format(" h:mm a")} </p>
                      <p>to</p>
                      <p className="TL-Loc-time-out">{" "}
                        {moment(item.exitTime).format(" h:mm a")}</p>
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                      sx={{
                        m: 0,
                        p: 0,
                        "& .MuiAvatar-root": {
                          width: "25px",
                          height: "25px",
                        },
                      }}
                    >
                      {timeLineImagePath(item.userPlace.internalCat) && (
                        <Avatar
                          alt={"No Dats"}
                          src={require(`../assets/timeline/${timeLineImagePath(
                            item.userPlace.internalCat
                          )}`)}
                        />
                      )}
                      {!timeLineImagePath(item.userPlace.internalCat) && (
                        <PlaceIcon />
                      )}
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent
                    className="TL-Loc-container"
                  >
                    <div className="d-flex ">
                      <p className="TL-Loc-name" onClick={()=>{
                        // console.log()
                        history.push(`/visit/${item.userPlace._id}`)

                      }}>
                        {item.userPlace.name}{" "}
                      </p>
                      <div className="TL-Loc-edit-button">
                        <button className="btn " onClick={() => EditLocationHandler(item)}><Edit2 className="editbtn"/></button>
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Item>
        </div>
        <div className="Timelinesview-map shadow">
          <Item>
            {!isLoaded && map.length === 0 && (
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  sx={{
                    textAlign: "center",
                    width: "200px",
                    height: "200px",
                  }}
                  alt={"No Dats"}
                  src={NoData}
                />
              </Box>
            )}
            {!isLoaded && map.length === 0 && (
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography>
                  <b>No Data</b>
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                height: "400px",
              }}
            >
              {map && map.length > 0 && (
                <TimeLineMap chooseSelectData={chooseSelectData}  visits={map} />
              )}
            </Box>
          </Item>
        </div>
      </div>
    </div>
  );
};

export default Timelines;
