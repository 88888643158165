import React, { useContext, useEffect, useState } from 'react';
import './BookMark.css'
import useAxios from '../../../helpers/Hooks/useAxios';
import { URLS } from "../../../helpers/URLS";
import { getUserData } from '../../../Utlities/utlities';
import LoaderAndToasterContext from '../../../Context/LoaderAndToaster';
import { styled } from '@mui/material/styles';
import { Plus, Save } from 'react-feather';
import moment from 'moment';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import { DialogBox } from '../../common/ModelDialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import ConfirmBox from '../../common/ConfirmBox';
import { useHistory } from 'react-router-dom';
import { File, X } from 'react-feather';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const BookMark = () => {
    const history = useHistory();
    const { fetchData } = useAxios();
    const { id } = getUserData();
    const { messageAlert } = useContext(LoaderAndToasterContext);
    const [modelPopup, setModelPopup] = useState(false);
    const [bookmarkList, setBookmarkList] = useState([]);
    const [bookmarkMasterList, setBookmarkMasterList] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [isConfirmBoxModel, setIsConfirmBoxModel] = useState(false);
    const [actionModel, setActionModel] = useState(false);
    const [deleteBookmarkData, setDeleteBookmarkData] = useState({
        bookmarkId: ""
    });
    const [createBookMakrkPayload, setCreateBookMakrkPayload] = useState({
        startTime: moment().subtract(5, 'days').format('YYYY-MM-DD'),
        endTime: moment().format('YYYY-MM-DD'),
        id: "",
        name: ''
    })
    const getBookMarksList = async () => {
        setBookmarkList([])
        setBookmarkMasterList([])
        const data = await fetchData({ url: URLS.BOOKMARK.replace("@userId", id), method: 'get', body: null, headersData: "", headerParameters: "" });
        if (data && data.bookmarks) {
            setBookmarkList(data.bookmarks)
            setBookmarkMasterList(data.bookmarks)
        }
    }

    const deleteBookmark = async () => {
        const data = await fetchData({ url: URLS.BOOKMARK.replace("@userId", id) + '/' + deleteBookmarkData.bookmarkId, method: 'delete', body: null, headersData: "", headerParameters: "" });
        if (data && data.message) {
            messageAlert(data.message, 'error')
        } else {
            messageAlert('Bookmark deleted successfully', 'success')
            confirmBoxHandle()
            getBookMarksList();

        }
    }

    const modelHandle = () => {
        setModelPopup(!modelPopup)
    }

    useEffect(() => {
        getBookMarksList()

    }, []);

    const perPage = 7;
    const [page, setPage] = useState(1);
    const HandleChangePage = (event, newValue) => {
        setPage(newValue)
    };

    const confirmBoxHandle = () => {
        if (isConfirmBoxModel) {
            setDeleteBookmarkData({
                bookmarkId: ""
            })
        }
        setIsConfirmBoxModel(!isConfirmBoxModel);

    }

    const actionModelHandle = () => {
        setActionModel(!actionModel)
    }

    const bookMarkChangeHandler = (e) => {
        setCreateBookMakrkPayload({
            ...createBookMakrkPayload,
            [e.target.name]: e.target.value
        })
    }

    React.useEffect(() => {
        const keyValues = ["name"]
        if (searchInput) {
            const search = bookmarkMasterList.filter(item => {
                let status = false;
                keyValues.forEach(key => {
                    if (item[key] && item[key].toLowerCase().includes(searchInput.toLowerCase())) {
                        status = true;
                    }
                })
                return status;
            })
            // console.log(item)
            setBookmarkList([...search])

        } else {
            setBookmarkList([...bookmarkMasterList])
        }
    }, [searchInput])


    return (
        <>
            <div className='Bookamark-view'>


                {/* ConfirmBox */}
                <ConfirmBox
                    isConfirmBoxModel={isConfirmBoxModel}
                    title="Delete Bookmark"
                    message="Are you sure you want to delete this bookmark?"
                    onCancel={confirmBoxHandle}
                    onConfirm={() => deleteBookmark()} />

                <div className='Bookmarkview-search shadow'>
                    <div>
                        <Paper className='Bookmark-search-bar'>
                            <InputBase
                                onChange={(e) => setSearchInput(e.target.value)}
                                value={searchInput}
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search user"
                                inputProps={{ 'aria-label': 'search google maps' }}
                            />
                            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                    </div>
                    <div className='Bookmark-add-button '>
                        <button className=' Bookmark-btn'
                            onClick={actionModelHandle}>
                            <Plus /> &nbsp;Add Bookmark
                        </button>
                    </div>
                </div>

                <div className='card shadow Bookmarkview-table'>
                    <div className='Bookmarkview-table-container'>
                        <Table aria-label="simple table">
                            <TableHead className='Bookmarkview-table-header'>
                                <TableRow>
                                    <TableCell align='center' className='Bookmarkview-table-heading'>S.No #</TableCell>
                                    <TableCell align='center' className='Bookmarkview-table-heading'>Bookmark Name</TableCell>
                                    <TableCell align='center' className='Bookmarkview-table-heading'>From Date</TableCell>
                                    <TableCell align='center' className='Bookmarkview-table-heading'>To Date</TableCell>
                                    <TableCell align='center' className='Bookmarkview-table-heading'>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bookmarkList.slice((page - 1) * perPage, ((page - 1) * perPage) + perPage).map((row, index) =>
                                    <TableRow className='Bookmarkview-table-rowdata' hover key={row.id} >
                                        <TableCell align='center' className='Bookmarkview-table-rowdata'>{((page - 1) * perPage) + 1 + index}</TableCell>
                                        <TableCell align='center' className='Bookmarkview-table-rowdata'>{row.name}</TableCell>
                                        <TableCell align='center' className='Bookmarkview-table-rowdata'>{moment(row.startTime).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell align='center' className='Bookmarkview-table-rowdata'>{moment(row.endTime).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell align='center' className='Bookmarkview-table-rowdata'>
                                            <Chip sx={{
                                                marginRight: '10px',
                                            }}
                                                onClick={() => {
                                                    history.push(`/bookmark/${row.id}`)
                                                }}
                                                label="View" />
                                            <Chip sx={{
                                                marginRight: '10px',
                                            }} onClick={() => {
                                                // console.log(row)
                                                setCreateBookMakrkPayload({
                                                    startTime: moment(row.startTime).format('YYYY-MM-DD'),
                                                    endTime: moment(row.endTime).format('YYYY-MM-DD'),
                                                    id: row.id,
                                                    name: row.name
                                                })
                                                actionModelHandle()

                                            }}
                                                label="Edit" />
                                            <Chip onClick={() => {
                                                setDeleteBookmarkData({ ...row, bookmarkId: row.id })
                                                confirmBoxHandle()
                                                // deleteBookmark(row.id)
                                            }} label="Delete" />

                                        </TableCell>
                                    </TableRow>)}

                            </TableBody>
                        </Table>
                        <Box sx={{ mt: 1, textAlign: "center" }}>
                            <Pagination
                                sx={{
                                    "& .Mui-selected": {
                                        backgroundColor: '#25478e !important',
                                        color: '#fff',
                                    }
                                }}
                                page={page} onChange={HandleChangePage} count={Math.ceil(bookmarkList.length / perPage)} variant="outlined" shape="rounded" />
                        </Box>
                    </div>
                </div>
                {/* Create Book Mark */}
                <DialogBox
                    className='addbookmark-modal'
                    onClose={actionModelHandle}
                    aria-labelledby="customized-dialog-title"
                    open={actionModel}

                >
                    <DialogTitle id="customized-dialog-title"
                        className='addbookmark-modal-header'
                        onClose={actionModelHandle} >
                        {/* Share */}
                        {createBookMakrkPayload.id ? 'Edit Bookmark' : 'Create Bookmark'}

                    </DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            className='addbookmark-modal-textfields'
                            name="name"
                            onChange={bookMarkChangeHandler}
                            label="Bookmark Name"
                            value={createBookMakrkPayload.name}

                        />
                        <div className='addbookmark-modal-dateContainer'>
                            <TextField
                                className='addbookmark-modal-startdate'
                                name='startTime'
                                onChange={bookMarkChangeHandler}
                                value={createBookMakrkPayload.startTime}
                                type="date"
                                label="From Date"

                            />
                            <TextField
                                className='addbookmark-modal-enddate'
                                name='endTime'
                                onChange={bookMarkChangeHandler}
                                value={createBookMakrkPayload.endTime}
                                type="date"
                                label="To Date"

                            />
                        </div>
                    </DialogContent>
                    <DialogActions
                        className='addbookmark-modal-footer'
                    >
                        <button
                            className='btn btn-outline-primary btn-sm'
                            onClick={(e) => {
                                setCreateBookMakrkPayload({
                                    startTime: moment().subtract(5, 'days').format('YYYY-MM-DD'),
                                    endTime: moment().format('YYYY-MM-DD'),
                                    name: ''
                                })
                                actionModelHandle()

                            }}
                        ><X className='Icons-bookmark' />&nbsp;Cancel </button>
                        <button
                            className='btn btn-primary btn-sm'
                            onClick={async (e) => {
                                if (createBookMakrkPayload.id) {
                                    const payload = {
                                        startTime: (moment(createBookMakrkPayload.startTime).unix() * 1000).toString(),
                                        endTime: (moment(createBookMakrkPayload.endTime).unix() * 1000).toString(),
                                        name: createBookMakrkPayload.name,
                                    }
                                    const data = await fetchData({ url: URLS.BOOKMARK.replace("@userId", id) + "/" + createBookMakrkPayload.id, method: 'put', body: payload, headersData: "", headerParameters: "" });
                                    if (data && data.success) {
                                        actionModelHandle()
                                        setCreateBookMakrkPayload({
                                            startTime: moment().subtract(5, 'days').format('YYYY-MM-DD'),
                                            endTime: moment().format('YYYY-MM-DD'),
                                            name: ''
                                        })
                                        messageAlert(data.message, 'success')
                                        getBookMarksList()

                                    }
                                    if (data.code) {
                                        if (data.message && data.message.length) {
                                            messageAlert(data.message[0].startTime || data.message[0].name, 'error')
                                        }
                                        return null;
                                    }
                                    if (data.success === false) {
                                        messageAlert(data.message, 'error')
                                        return null;
                                    }

                                } else {
                                    const payload = {
                                        startTime: (moment(createBookMakrkPayload.startTime).unix() * 1000).toString(),
                                        endTime: (moment(createBookMakrkPayload.endTime).unix() * 1000).toString(),
                                        name: createBookMakrkPayload.name,
                                    }
                                    const data = await fetchData({ url: URLS.BOOKMARK.replace("@userId", id), method: 'post', body: payload, headersData: "", headerParameters: "" });
                                    if (data && data.id && data.name) {
                                        actionModelHandle()
                                        setCreateBookMakrkPayload({
                                            startTime: moment().subtract(5, 'days').format('YYYY-MM-DD'),
                                            endTime: moment().format('YYYY-MM-DD'),
                                            name: ''
                                        })
                                        messageAlert("Bookmark successfully created", 'success')
                                        getBookMarksList()

                                    }
                                    if (data.code) {
                                        if (data.message && data.message.length) {
                                            messageAlert(data.message[0].startTime || data.message[0].name, 'error')
                                        }
                                        return null;
                                    }
                                    if (data.success === false) {
                                        messageAlert(data.message, 'error')
                                        return null;
                                    }
                                }
                            }}
                        >
                            <Save className='Icons-bookmark' /> &nbsp;{createBookMakrkPayload.id ? 'Update' : 'Create'}
                        </button>
                    </DialogActions>
                </DialogBox>
                {/* Create bok=ok mark end */}

            </div >
        </>
    )
}
export default BookMark;