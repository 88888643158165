import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import useAxios from "../../../helpers/Hooks/useAxios";
import { URLS } from "../../../helpers/URLS";
import { getUserData } from "../../../Utlities/utlities";
import TextField from "@mui/material/TextField";

import { DialogBox } from "../../common/ModelDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoaderAndToasterContext from "../../../Context/LoaderAndToaster";
import { useHistory } from "react-router-dom";
import { X, Save } from "react-feather";
const OrgModel = ({getOrganization,isEdit,row}) => {
    const history = useHistory();
    const { messageAlert } = useContext(LoaderAndToasterContext);
    const { fetchData } = useAxios();
    const { id } = getUserData();
  const [modelPopup, setModelPopup] = useState(false);
  const [error, setError] = useState([]);
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    password: "",
    website: "",
  });

  const ReserForm = () => {
    setPayload({
      name: "",
      email: "",
      password: "",
      website: "",
    });
  };

  const onChangeHandler = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    });
  };
  const modelHandle = () => {
    if (modelPopup) {
      ReserForm();
    }
    setError([]);
    setModelPopup(!modelPopup);
  };

  return (
    <>
    {isEdit === false && (<Button
        onClick={modelHandle}
        variant="contained"
        color="primary"
        startIcon={<PersonAddIcon sx={{ fontSize: "25px" }} />}
      >
        Add Organization
      </Button>)}
      {isEdit === true && (<Button
        onClick={(e)=>{
          // console.log(row)
          setPayload({
            ...row
          })
          modelHandle();
        }}
        variant="contained"
        color="primary"
        startIcon={<PersonAddIcon sx={{ fontSize: "25px" }} />}
        sx={{ float: "right" }}
      >
        Edit Organization
      </Button>)}
      <DialogBox
        onClose={modelHandle}
        aria-labelledby="customized-dialog-title"
        open={modelPopup}
        // fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{ backgroundColor: "#25478e", color: "#fff", padding: "10px" }}
          onClose={modelHandle}
        >
          <h4>{isEdit?"Edit":"Add"} Organization</h4>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Box
              component="div"
              sx={{
                textAlign: "center",

                "& .MuiFormControl-root": { m: 2 },
                "& .MuiAutocomplete-root": { m: 1, width: "40ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="Form-view">
                <div className="d-flex">
                  <TextField
                    sx={{ m: 1, width: "40ch" }}
                    onChange={onChangeHandler}
                    value={payload.name}
                    error={error[0]}
                    helperText={error[0]}
                    name="name"
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                  />
                  <TextField
                    sx={{ m: 1, width: "40ch" }}
                    onChange={onChangeHandler}
                    name="email"
                    disabled={isEdit}
                    value={payload.email}
                    error={error[1]}
                    helperText={error[1]}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                  />
                </div>
                <div className="d-flex">
                  <TextField
                    type="password"
                    sx={{ m: 1, width: "40ch" }}
                    onChange={onChangeHandler}
                    value={payload.password}
                    error={error[2]}
                    helperText={error[2]}
                    name="password"
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                  />
                  <TextField
                    sx={{ m: 1, width: "40ch" }}
                    onChange={onChangeHandler}
                    name="website"
                    value={payload.website}
                    error={error[3]}
                    helperText={error[3]}
                    id="outlined-basic"
                    label="Website"
                    variant="outlined"
                  />
                </div>
              </div>
            </Box>
          </Typography>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#9bbde0", color: "#4a6ca1" }}>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            variant="contained"
            onClick={(e) => {
              modelHandle();
            }}
          >
            <X sx={{ fontSize: "25px" }} />
            &nbsp;Cancel
          </Button>

          <Button onClick={async(e)=>{
                     const customError = [];
                     const validateEmail = (email) => {
                       return String(email)
                         .toLowerCase()
                         .match(
                           /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                         );
                     };
                     if (!payload.email) {
                       customError[1] = "Email is required";
                     }
                     if (payload.email) {
                       if (!validateEmail(payload.email)) {
                         customError[1] = "Email is not valid";
                       }
                     }
                     if (!payload.name) {
                        customError[0] = "Name is required";
                      }
                      if (!payload.password) {
                        customError[2] = "Password is required";
                      }
                      if (!payload.website) {
                        customError[3] = "website is required";
                      }
                      setError(customError);
                      if (customError.length) {
                        return null;
                      } else {
                        setError([]);
                      }
                      if(!isEdit){
                      const data = await fetchData({
                        url: URLS.ORGANIZATIONS,
                        method: "post",
                        body: { ...payload },
                        headersData: "",
                      });
                      if (data.code) {
                        messageAlert(data.message[0], "error")
                    } else {
                        messageAlert(data.message, "success")
                        modelHandle();
                        getOrganization()
                    }
                  } else {
                    const data = await fetchData({
                      url: URLS.UPDATE_ORGANIZATIONS+row._id,
                      method: "put",
                      body: { ...payload },
                      headersData: "",
                    });
                    if (data.code) {
                      messageAlert(data.message[0], "error")
                  } else {
                      messageAlert(data.message, "success")
                      modelHandle();
                      getOrganization()
                  }
                  }

          }}
            style={{
              backgroundColor: "green",
              color: "#fff",
              paddingLeft: "10px",
            }}
          >
            <Save sx={{ fontSize: "25px" }} />
            &nbsp; {isEdit?"Update":"Save"}
          </Button>
        </DialogActions>
      </DialogBox>
    </>
  );
};

export default OrgModel;
