import React from 'react'
import Logo from '../../../Assets/keeptrax_logo_1.jpg';
import { Link, useHistory } from 'react-router-dom';
import './Header.css';
import { Bell, Mail, User, Power, } from 'react-feather';

function Header({currentUserData,userLogout}) {
    return (
        <>
            <div className='container-fluid Header'>
                <nav className='navbar  '>
                    <Link to="" className='navbar-brand p-0'>
                        <img className='logo img-fluid' src={Logo} alt="logo" />
                    </Link>
                    <div className=' float-right content'>
                        <ul className='navbar-nav p-0 flex-row'>
                            {/* <li className='p-2 nav-item items'><Bell /></li>
                            <li className='p-2 nav-item items'><Mail /></li> */}
                            <div className='dropdown'>
                                <button type="button" className="btn dropdown-toggle UserButton">
                                    <User />
                                    {currentUserData.firstname ? currentUserData.firstname : currentUserData.email}
                                </button>
                                <ul className="dropdown-nav shadow">
                                    <li>
                                        <Link to="/profile" className='nav-link '>
                                            <div className="nav-item mt-2 dropdown-item">
                                                <span className="fw-bold "> <User /> Profile</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <button type='button' onClick={userLogout} className='btn btn-outline-danger dropdown-item'> <Power /> Logout</button>
                                    </li>
                                </ul>
                            </div>
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Header