import React, { useEffect, useState } from 'react';
import './UserTimeline.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import useAxios from '../../../helpers/Hooks/useAxios';
import { URLS } from "../../../helpers/URLS";
import { getUserData, timeLineImagePath } from '../../../Utlities/utlities';
import LoaderAndToasterContext from '../../../Context/LoaderAndToaster';
import PlaceIcon from '@mui/icons-material/Place';
import TimeLineMap from '../Dashboard/TimeLineMap';
import { useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import NoData from "../assets/no_location_found_.webp";
import debounce from 'lodash.debounce';






const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const UserTimeLine = () => {
    const [palces, setPlaces] = useState([]);
    const { fetchData } = useAxios();
    const [visitData, setVisitData] = useState({});
    const { id } = getUserData();
    const [map, setMap] = useState([]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isManager, setIsManager] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const { userid } = useParams();
    // let { userid } = {
    //     userid: "620e40147c367294614f9472"
    // }
    const [userData, setUserData] = React.useState({
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        birthDate: new Date(),
        image_url: "",
        gender: ""
    });
    const [dateParameters, setDateParameters] = useState({
        startDate: moment().subtract(120, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    })

    const getUserDetails = async () => {
        try {
            const data = await fetchData({ url: `/${URLS.user}/${userid}`, method: 'get', body: null, headersData: "", headerParameters: "" });
            if (data.status === 200) {
                setIsManager(true)
                setUserData(data.data);
                getTrax()
            } else {
                setIsManager(false)
            }
        }
        catch (err) {
            console.log("ERROR", err);
        }
    }
    const getTrax = async () => {
        if (isManager) {
            setVisitData({})
            setIsLoaded(true)
            setSearchInput("");
            const currentDate = moment(dateParameters.endDate).format('YYYY-MM-DD');
            const pastDate = moment(dateParameters.startDate).format('YYYY-MM-DD');
            const headers = {
                'startdate': `${pastDate}`,
                'enddate': `${currentDate}`,
                'userid': `${userid}`
            }
            try {
                const data = await fetchData({ url: URLS.getTrax, method: 'get', body: null, headersData: "", headerParameters: headers });
                setVisitData(data);
                const visitNames = data?.visits?.map(item => item.userPlace.name);
                setPlaces(visitNames);
                setIsLoaded(false);
            }
            catch (err) {
                console.log("ERROR", err);
            }
        }

    }

    const [viewVist, setViewVist] = useState({
        locality: "",
        name: "",
        rating: "",
        sublocality: "",
        createdOn: "",
        entryTime: "",
        exitTime: "",
    });

    const chooseSelectData = (data) => {
        if (data) {
            const { locality, name, rating, sublocality } = data.userPlace;
            setViewVist({
                locality, name, rating, sublocality,
                createdOn: data.createdOn,
                entryTime: data.entryTime,
                exitTime: data.exitTime
            })
        }
    }
    useEffect(() => {
        getTrax();
    }, [dateParameters, isManager])

    useEffect(() => {
        getUserDetails()
    }, [])

    useEffect(() => {
        if (visitData && visitData.visits) {
            setMap(visitData.visits)
        }
    }, [visitData])
    const onChangeHandler = (e) => {
        setDateParameters({
            ...dateParameters,
            [e.target.name]: e.target.value
        })
    }




    useEffect(() => {
        if (searchInput) {
            if (visitData && visitData.visits) {
                const filteredData = visitData.visits.filter(item => item.userPlace.name.toLowerCase().includes(searchInput.toLowerCase()));
                setMap(filteredData);
            }
        } else {
            if (visitData && visitData.visits) {
                setMap(visitData.visits)
            }
        }
        // console.log("searchInput", searchInput)
    }, [searchInput])


    return (
        <>
            <div className='UserTimelines-view'>
                <div className="UserTimelinesview-searchTab shadow">
                    <div className='UserTimelinesview-Udata'>
                        <Typography>Name : {userData.firstname} {userData.lastname}</Typography>
                        <Typography>Email : {userData.email}</Typography>
                        {userData.firstname && (<Typography>Mobile : {userData.mobile ? userData.mobile : "Not available"}</Typography>)}
                    </div>
                    <div>
                        <Paper
                            component="form"
                            sx={{ p: '2px 4px', mt: 1, display: 'flex', alignItems: 'center', width: 250 }}
                        >
                            <InputBase
                                onChange={(e) => setSearchInput(e.target.value)}
                                value={searchInput}
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search Location"
                                inputProps={{ 'aria-label': 'search google maps' }}
                            />
                            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>

                        </Paper>
                    </div>
                    <div>
                        <Box
                            component="div"
                            sx={{
                                textAlign: 'center',
                                '& .MuiTextField-root': { m: 1, width: '30ch' },
                                '& .MuiFormControl-root': { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>

                                <TextField
                                    id="date"
                                    name='startDate'
                                    value={dateParameters.startDate}
                                    onChange={onChangeHandler}
                                    label="From"
                                    type="date"
                                    sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    id="date-1"
                                    name='endDate'
                                    value={dateParameters.endDate}
                                    onChange={onChangeHandler}
                                    label="To"
                                    type="date"
                                    sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {/* <Button variant="contained" color="primary" sx={{ margin: 1 }}> Serach </Button> */}
                            </div>
                        </Box>
                    </div>

                </div>

                <div className='UserTimelines-visitcontainer'>
                    <div className='UserTimelines-Data shadow'>
                        <Item>
                            {!isLoaded && map.length === 0 &&
                                <Box
                                    sx={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                                    <Avatar sx={{
                                        textAlign: 'center',
                                        width: '200px',
                                        height: '200px'
                                    }} alt={'No Dats'} src={NoData} />

                                </Box>}
                            {!isLoaded && map.length === 0 && <Box sx={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                                <Typography><b>No Data</b></Typography>

                            </Box>}

                            <Timeline className="UserTimelines-visitsdata">
                                {map?.map((item, index) => (
                                    <TimelineItem key={item._id}>
                                        <TimelineOppositeContent>
                                            <div className='UTL-Loc-time'>
                                                <p className='UTL-Loc-time-in'>{moment(item.entryTime).format(" h:mm a")}</p>
                                                <p>to</p>
                                                <p className='UTL-Loc-time-out'>{moment(item.exitTime).format(" h:mm a")}</p>
                                            </div>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot sx={{
                                                m: 0,
                                                p: 0,
                                                "& .MuiAvatar-root": {
                                                    width: '25px',
                                                    height: '25px'
                                                },
                                            }}>
                                                {timeLineImagePath(item.userPlace.internalCat) && (<Avatar alt={'No Dats'}
                                                    src={require(`../assets/timeline/${timeLineImagePath(item.userPlace.internalCat)}`)} />)}
                                                {!timeLineImagePath(item.userPlace.internalCat) && (<PlaceIcon />)}
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent className='UTL-Loc-container'>

                                            <Typography className="UTL-Loc-name" >{item.userPlace.name}</Typography>
                                        </TimelineContent>
                                    </TimelineItem>))}
                            </Timeline>
                        </Item>
                    </div>
                    <div className='UserTimelines-map shadow'>
                        <Item>
                            {!isLoaded && map.length === 0 && <Box sx={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                                <Avatar sx={{
                                    textAlign: 'center',
                                    width: '200px',
                                    height: '200px'
                                }} alt={'No Dats'} src={NoData} />

                            </Box>}
                            {!isLoaded && map.length === 0 && <Box sx={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                                <Typography><b>No Data</b></Typography>

                            </Box>}
                            <Box
                                sx={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "400px"
                                }}>
                                {!isLoaded && map && map.length > 0 && <TimeLineMap chooseSelectData={chooseSelectData} visits={map} />}
                            </Box>

                            {/* {!isLoaded && map.length === 0 && <Box sx={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                        <Avatar sx={{
                            textAlign: 'center',
                            width: '200px',
                            height: '200px'
                        }} alt={'No Dats'} src={NoData} />

                    </Box>}
                    {!isLoaded && map.length === 0 && <Box sx={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                        <Typography><b>No Data</b></Typography>

                    </Box>}
                    {map && map.length > 0 && <TimeLineMap chooseSelectData={chooseSelectData} visits={map.slice(0,10)} />} */}
                        </Item>
                    </div>
                </div>
            </div>
        </>
    )


}

export default UserTimeLine;