import React, { useEffect, useContext, useState } from 'react'
import './Navbar.css';
import { Link, useHistory } from 'react-router-dom'
import KeeptraxLogo from '../../Assets/Keeptrax Logo.png';
import { getUserData, userProfile, getProfileData } from '../../Utlities/utlities';
import { baseURL } from '../../Utlities/Api';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import LoaderAndToasterContext from '../../Context/LoaderAndToaster';
import axios from 'axios';
import { LogOut } from 'react-feather';
import useAxios from '../../helpers/Hooks/useAxios';
import { URLS } from "../../helpers/URLS";



function Navbar({ children }) {
    const history = useHistory();
    const { fetchData } = useAxios();
    const { setIsLoader,messageAlert,setCurrentUseData,currentUserData } = useContext(LoaderAndToasterContext);
    const { id, firstname, email } = getUserData();

    const GetProfile = async () => {
        const data = await fetchData({ url: URLS.profile.replace("@id", id), method: 'get', body: null, headersData: "" });
        setCurrentUseData(data)
        userProfile(JSON.stringify(data));
    }
    useEffect(() => {
        if (id) {
            GetProfile()
        }
    }, [id]);

    // Logout 

    const userLogout = async () => {
        const data = await fetchData({ url: URLS.logout.replace("@id", id), method: 'delete', body: null, headersData: "" });
        if (data && data.logoutstatus) {
            localStorage.clear();
            history.push("/")
        }
        if(data && data.code){
            messageAlert(data.message, "error")
        }
    }



    // const Logouthandler = () => {        
    //     axios.post(urls.logout).then((res) => {
    //         localStorage.clear();
    //         console.log(res, "logout response")
    //         setIsLoggedIn(!res.data)
    //         // if (!isLoggedIn) {
    //         history.push(`/`)
    //         // }
    //     })
    // }



    return (
        <>
            <div className='container-fluid  navbar-login'>
                <nav className='navbar navbar-expand-lg '>
                    <Link to="" className='navbar-brand'>
                        <img className='navbar-login logo img-fluid' src={KeeptraxLogo} alt="logo" />
                    </Link>
                    {id && (<div className='navbar-nav float-right'>
                        <div>
                            <ul className='navbar-nav'>
                                <li className='nav-item'>
                                    <Link to="/dashboard" className='p-4 nav-item active'>Dashboard</Link>
                                </li>
                                <li className='nav-item'>
                                    <Link to="/timelines" className='p-4 nav-item'>Timelines</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="dropdown">
                            <button type="button" className="dropdown-button">
                                <i className="bi bi-person-circle navbar-person"></i> &nbsp;
                                {currentUserData.firstname ? currentUserData.firstname : currentUserData.email}
                            </button>
                            <ul className="dropdown-content shadow">
                                <Link to="/profile" className='mb-2 d-block'>Profile</Link>
                                <Link to="/bookmark" className='mb-2 d-block'>My Bookmarks</Link>
                                <Link to="/timelines" className='mb-2 d-block'>My Insights</Link>
                                <Link to="/timelines" className='mb-2 d-block'>Shared Trax</Link>
                             {currentUserData.role === "MANAGER" && (   <Link to="/manageusers" className='mb-2 d-block'>Manage Users</Link> )}
                                <button type='button' onClick={userLogout} className='btn btn-outline-danger'> <LogOut /> Logout</button>
                            </ul>
                        </div>

                    </div>)}
                </nav>
            </div>
            {children}

        </>
    )
}

export default Navbar