import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const ConfirmBox = ({
    isConfirmBoxModel=false,
    title='',
    message='',
    onConfirm=()=>{},
    onCancel=()=>{},
    confirmButtonText='Confirm',
    cancelButtonText='Cancel',
}) => {
    return <Dialog
        open={isConfirmBoxModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onConfirm}>{confirmButtonText}</Button>
            <Button onClick={onCancel} autoFocus>
                {cancelButtonText}
            </Button>
        </DialogActions>
    </Dialog>
}

export default ConfirmBox;