import { useState, useEffect, useContext } from "react";
import './EditLocation.css'
import Box from "@mui/material/Box";
import { DialogBox } from "../../common/ModelDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Rating from "@mui/material/Rating";
import { getUserData } from "../../../Utlities/utlities";
import useAxios from "../../../helpers/Hooks/useAxios";
import { URLS } from "../../../helpers/URLS";
import LoaderAndToasterContext from "../../../Context/LoaderAndToaster";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const EditLocation = ({
  isOpen = false,
  modelHandler,
  editLoactotionData,
  setEditLoactotionData,
  categoriesList,
  handleResponse,
}) => {
  console.log(editLoactotionData);
  const { fetchData } = useAxios();
  const { messageAlert } = useContext(LoaderAndToasterContext);
  const { id } = getUserData();
  const locationHandleChange = (event) => {
    setEditLoactotionData({
      ...editLoactotionData,
      data: {
        ...editLoactotionData.data,
        [event.target.name]: event.target.value,
      },
    });
  };
  // const [location, setLocation] = useState(editLoactotionData);

  return (
    <DialogBox
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      fullWidth
    // maxWidth="md"
    >
      <DialogTitle
        id="customized-dialog-title"
        style={{ backgroundColor: "#394671", color: "#fff" }}
        onClose={modelHandler}
      >
        Edit Location
        <IconButton
          aria-label="close"
          onClick={modelHandler}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          <Box
            component="div"
            sx={{
              // textAlign: 'center',
              "& .MuiTextField-root": { m: 1, width: "30ch" },
              "& .MuiFormControl-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <FormControl>
                {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => {
                    setEditLoactotionData({
                      ...editLoactotionData,
                      type: e.target.value,
                    });
                  }}
                  value={editLoactotionData.type}
                >
                  <FormControlLabel
                    value="Location"
                    control={<Radio />}
                    label="Location"
                  />
                  <FormControlLabel
                    value="Rating"
                    control={<Radio />}
                    label="Rating"
                  />
                  <FormControlLabel
                    value="Comment"
                    control={<Radio />}
                    label="Note"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </Box>
          {editLoactotionData.type === "Location" && (
            <Box
              component="div"
              sx={{
                // textAlign: 'center',
                "& .MuiTextField-root": { m: 1, width: "30ch" },
                "& .MuiFormControl-root": { m: 1, width: "30ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  name="name"
                  value={editLoactotionData.data.name}
                  onChange={locationHandleChange}
                  variant="outlined"
                />
                <TextField
                  disabled
                  id="outlined-basic"
                  label="Location"
                  name="sublocality"
                  value={editLoactotionData.data.sublocality}
                  onChange={locationHandleChange}
                  variant="outlined"
                />
                <TextField
                  disabled
                  id="outlined-basic"
                  label="city"
                  name="locality"
                  value={editLoactotionData.data.locality}
                  onChange={locationHandleChange}
                  variant="outlined"
                />
                <TextField
                  disabled
                  id="outlined-basic"
                  label="state"
                  name="administrative_area_level_1"
                  value={editLoactotionData.data.administrative_area_level_1}
                  onChange={locationHandleChange}
                  variant="outlined"
                />
                <TextField
                  disabled
                  id="outlined-basic"
                  name="country"
                  value={editLoactotionData.data.country}
                  onChange={locationHandleChange}
                  label="country"
                  variant="outlined"
                />
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={editLoactotionData.data.internalCat}
                    label="internalCat"
                    name="internalCat"
                    onChange={locationHandleChange}
                  >
                    {categoriesList.map((ctrg) => (
                      <MenuItem key={ctrg._id} value={ctrg.internalCat}>
                        {ctrg.internalCat}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Box>
          )}
          {editLoactotionData.type === "Rating" && (
            <Box
              component="div"
              sx={{
                // textAlign: 'center',
                "& .MuiTextField-root": { m: 1, width: "30ch" },
                "& .MuiFormControl-root": { m: 1, width: "30ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <Box
                  sx={{
                    textAlign: "center",
                    "& > legend": { mt: 2 },
                  }}
                >
                  <Rating
                    name="rating"
                    value={editLoactotionData.data.rating}
                    onChange={async (e) => {
                      locationHandleChange(e);
                      const url = `${URLS.PLACES.replace("@userId", id)}/${editLoactotionData.placeID
                        }`;
                      const payload = {
                        rating: e.target.value,
                      };
                      const resp = await fetchData({
                        url: url,
                        method: "put",
                        body: payload,
                        headersData: "",
                        headerParameters: "",
                      });
                      if (resp && resp.response && resp.response.id) {
                        messageAlert("Rating updated successfully", "success");
                        handleResponse(true);
                      } else {
                        messageAlert(resp.message, "error");
                      }
                    }}
                  />
                </Box>
              </div>
            </Box>
          )}

          {editLoactotionData.type === "Comment" && (
            <Box component="div" sx={{}} noValidate autoComplete="off">
              <div>
                <TextField
                  fullWidth
                  label="Note"
                  name="notes"
                  value={editLoactotionData.data.notes}
                  placeholder="Note"
                  onChange={locationHandleChange}
                  multiline
                  rows={2}
                  maxRows={4}
                />
              </div>
            </Box>
          )}
        </Typography>
      </DialogContent>
      {editLoactotionData.type !== "Rating" && (
        <DialogActions
          className="EditLocation-footer"
        >
          <div>
            {editLoactotionData.type === "Location" && (
              <Box>
                {/* <Button
                  variant="contained"
                  sx={{
                    marginRight: "1rem",
                  }}
                  color="primary"
                  onClick={modelHandler}
                >                  
                  close
                </Button> */}
                {/* Delete Button */}
                <button className="btn btn-outline-primary"
                  onClick={async () => {
                    const url = `${URLS.VISIT.replace("@userId", id)}/${editLoactotionData.locationID}`;
                    const resp = await fetchData({
                      url: url,
                      method: "delete",
                      body: "",
                      headersData: "",
                      headerParameters: "",
                    });
                    if (resp && resp.message && !resp.code) {
                      messageAlert(resp.message, "success");
                      handleResponse(true);
                    } else {
                      messageAlert(resp.message, "error");
                    }
                  }}
                >
                  Delete Visit
                </button>
                {/* Update Button */}
                <button className="btn btn-primary"
                  onClick={async () => {
                    const url = `${URLS.PLACES.replace("@userId", id)}/${editLoactotionData.placeID
                      }`;
                    const payload = {
                      administrative_area_level_1: editLoactotionData.data.administrative_area_level_1,
                      country: editLoactotionData.data.country,
                      internalCat: [editLoactotionData.data.internalCat],
                      locality: editLoactotionData.data.locality,
                      name: editLoactotionData.data.name,
                      sublocality: editLoactotionData.data.sublocality
                    };
                    const resp = await fetchData({
                      url: url,
                      method: "put",
                      body: payload,
                      headersData: "",
                      headerParameters: "",
                    });
                    if (resp && resp.response && resp.response.id) {
                      messageAlert("Location updated successfully", "success");
                      handleResponse(true);
                    } else {
                      messageAlert(resp.message[0] || resp.message, "error");
                    }

                  }}
                >
                  Update
                </button>
              </Box>
            )}

            {editLoactotionData.type === "Comment" && (
              <Box>
                {/* <Button
                  variant="contained"
                  sx={{
                    marginRight: "1rem",
                  }}
                  color="primary"
                  onClick={modelHandler}
                >
                  close
                </Button> */}
                {/* Delete Button */}
                <button className="btn btn-outline-primary"
                  onClick={async () => {
                    const url = `${URLS.PLACES.replace("@userId", id)}/${editLoactotionData.placeID}/trax/${editLoactotionData.locationID}`;
                    const payload = {
                      notes: "",
                    };
                    const resp = await fetchData({
                      url: url,
                      method: "put",
                      body: payload,
                      headersData: "",
                      headerParameters: "",
                    });
                    if (resp && resp.response && resp.response.id) {
                      messageAlert("Note Deleted successfully", "success");
                      handleResponse(true);
                    } else {
                      messageAlert(resp.message, "error");
                    }

                  }}
                >
                  Delete Note
                </button>
                {/* Update Button */}
                <button className="btn btn-primary"
                  onClick={async () => {
                    const url = `${URLS.PLACES.replace("@userId", id)}/${editLoactotionData.placeID}/trax/${editLoactotionData.locationID}`;
                    const payload = {
                      notes: editLoactotionData.data.notes,
                    };
                    const resp = await fetchData({
                      url: url,
                      method: "put",
                      body: payload,
                      headersData: "",
                      headerParameters: "",
                    });
                    if (resp && resp.response && resp.response.id) {
                      messageAlert("Note updated successfully", "success");
                      handleResponse(true);
                    } else {
                      messageAlert(resp.message, "error");
                    }

                  }}
                >
                  Update
                </button>
              </Box>
            )}
          </div>
        </DialogActions>
      )}
    </DialogBox>
  );
};

export default EditLocation;
