import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Share2 } from 'react-feather';
import Button from '@mui/material/Button';
import moment from 'moment';
import { InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './SharedTrax.css';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import useAxios from '../../../helpers/Hooks/useAxios';
import { URLS } from "../../../helpers/URLS";
import { getUserData } from '../../../Utlities/utlities';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
// import TablePagination from '@mui/material/TablePagination';

import { DialogBox } from '../../common/ModelDialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Chip from '@mui/material/Chip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import LoaderAndToasterContext from '../../../Context/LoaderAndToaster';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const SharedTrax = () => {
    const history = useHistory();
    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event, newValue) => {
        setSearchInput("");
        setTabValue(newValue);
    };
    const [modelPopup, setModelPopup] = useState(false);
    const [actionModel, setActionModel] = useState(false);
    const [updateModel, setUpdateModel] = useState(false);
    const [updatePayload, setUpdatePayload] = useState({
        shareId: "",
        bookmark: "",
        expiresAt: 4,
        startTime: "",
        endTime: "",
        isSharingImages: false,
        name: ""
    })
    const { messageAlert } = useContext(LoaderAndToasterContext);
    const [emails, setEmail] = useState([]);
    const [enterEmail, setEnterEmail] = useState('');
    const [error, setError] = useState("");
    const [searchInput, setSearchInput] = useState('');
    const { fetchData } = useAxios();
    const { id } = getUserData();
    const [sharedTrax, setSharedTrax] = useState({
        received: [],
        sent: []
    });
    const [masterSharedTrax, setMasterSharedTrax] = useState({
        received: [],
        sent: []
    });
    const [newSharePayload, setNewSharePayload] = useState({
        email: [],
        type: "Share an existing bookmark"
    });

    const [createBookMakrkPayload, setCreateBookMakrkPayload] = useState({
        startTime: moment().subtract(5, 'days').format('YYYY-MM-DD'),
        endTime: moment().format('YYYY-MM-DD'),
        id: "",
        name: '',
        isSharingImages: false,
        expiresAt: 4,
        isSharingImages: false
    })
    const [bookmarkList, setBookmarkList] = useState([]);

    const bookMarkChangeHandler = (e) => {
        setCreateBookMakrkPayload({
            ...createBookMakrkPayload,
            [e.target.name]: e.target.value
        })
    }

    const getSentSharedTrax = async () => {
        setSearchInput("")
        const data = await fetchData({ url: URLS.GET_SHARED_TRAX.replace("@userid", id), method: 'get', body: null, headersData: "", headerParameters: "" });
        setSharedTrax(data);
        setMasterSharedTrax(data);
    }
    const getBookMarksList = async () => {
        const data = await fetchData({ url: URLS.BOOKMARK.replace("@userId", id), method: 'get', body: null, headersData: "", headerParameters: "" });
        // console.log(data);
        if (data && data.bookmarks) {
            setBookmarkList(data.bookmarks)
        }
    }

    useEffect(() => {
        getSentSharedTrax()
        getBookMarksList()

    }, [])
    const [sentPage, setSentPage] = useState(1);
    const sentHandleChange = (event, newValue) => {
        setSentPage(newValue);
    };

    const perPage = 7;
    const [receivedPage, setReceivedPage] = useState(1);
    const receivedHandleChange = (event, newValue) => {
        setReceivedPage(newValue)
    };

    const updateChangeHandler = (e) => {
        setUpdatePayload({
            ...updatePayload,
            [e.target.name]: e.target.value
        })
    }


    const modelHandle = () => {
        setModelPopup(!modelPopup)
    }

    const actionModelHandle = () => {
        setActionModel(!actionModel)
    }

    const updateModelHandle = () => {
        setUpdateModel(!updateModel);
    }

    const isInList = (email) => {
        return emails.includes(email);
    }

    const isEmail = (email) => {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    }

    const isValid = (email) => {
        let error = null;

        if (isInList(email)) {
            error = `${email} has already been added.`;
        }

        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        return error;
    }
    const emailHandleChange = evt => {
        setError('')
        setEnterEmail(evt.target.value);
    };

    const handlePaste = evt => {
        evt.preventDefault();

        var paste = evt.clipboardData.getData("text");
        var emailsCheck = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

        if (emailsCheck) {
            var toBeAdded = emailsCheck.filter(email => !isInList(email));
            setEmail(emails => [...emails, toBeAdded]);
        }
    };
    const handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = enterEmail.trim();
            setError(isValid(value))

            if (value && !isValid(value)) {
                setEmail(emails => [...emails, value]);
                setEnterEmail('')
            }
        }
    };

    const handleDelete = (email) => {
        setEmail(emails => [...emails.filter(e => e !== email)]);
    };

    const resetData = () => {
        setError('');
        setEnterEmail('');
        setModelPopup(false);
        setActionModel(false);
        setEmail([]);
        setNewSharePayload({
            email: [],
            type: "Share an existing bookmark"
        });

        setCreateBookMakrkPayload({
            startTime: moment().subtract(5, 'days').format('YYYY-MM-DD'),
            endTime: moment().format('YYYY-MM-DD'),
            id: "",
            name: '',
            isSharingImages: false,
            expiresAt: 4,
            isSharingImages: false
        })
    }
    const expireDateCheck = (date) => {
        return moment().isAfter(moment(date))
    }

    useEffect(() => {
        if (tabValue === 0) {
            if (searchInput) {
                const keyValues = ["sender.email", "sender.firstname", "sender.lastname", "share.name"];
                const search = masterSharedTrax.received.filter(item => {
                    let status = false;
                    keyValues.forEach(key => {
                        let splitKey = key.split(".");
                        if (splitKey.length === 2) {
                            if (item[splitKey[0]] && item[splitKey[0]][splitKey[1]] && item[splitKey[0]][splitKey[1]].toLowerCase().includes(searchInput.toLowerCase())) {
                                status = true;
                            }
                        }
                        else {
                            if (item[key] && item[key].toLowerCase().includes(searchInput.toLowerCase())) {
                                status = true;
                            }

                        }
                    })
                    return status;
                })
                setSharedTrax({
                    ...masterSharedTrax,
                    received: [...search]
                })

            } else {
                setSharedTrax({ ...masterSharedTrax })
            }
        } else if (tabValue === 1) {
            if (searchInput) {
                const keyValues = ["name", "recipients"];
                const search = masterSharedTrax.sent.filter(item => {
                    let status = false;
                    keyValues.forEach(key => {
                        let splitKey = key.split(".");
                        if (splitKey.length === 2) {
                            if (item[splitKey[0]] && item[splitKey[0]][splitKey[1]] && item[splitKey[0]][splitKey[1]].toLowerCase().includes(searchInput.toLowerCase())) {
                                status = true;
                            }
                        }
                        else {
                            if ("recipients" === key) {
                                const recipients = item.recipients.map(li => {
                                    if (li.firstname) {
                                        return li.firstname + " " + li.lastname
                                    } else {
                                        return li.email;
                                    }
                                }).join(",")
                                if (recipients && recipients.toLowerCase().includes(searchInput.toLowerCase())) {
                                    status = true;
                                }

                            } else {
                                if (item[key] && item[key].toLowerCase().includes(searchInput.toLowerCase())) {
                                    status = true;
                                }
                            }

                        }
                    })
                    return status;
                })
                setSharedTrax({
                    ...masterSharedTrax,
                    sent: [...search]
                })

            } else {
                setSharedTrax({ ...masterSharedTrax })
            }
        }
    }, [searchInput])

    return (
        <div className='Traxview'>
            <div className='Traxview-searchTab shadow'>
                <div >
                    <Paper className='Traxview-search-bar'>
                        <InputBase
                            onChange={(e) => setSearchInput(e.target.value)}
                            value={searchInput}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search Trax"
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />
                        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>

                    </Paper>
                </div>
                <div className='Traxview-add-button'>
                    <button className=' Traxview-btn'
                        onClick={modelHandle}>
                        <Share2 /> &nbsp;New Trax
                    </button>
                </div>

            </div>
            <div className='card shadow Traxview-Table'>
                <div className='Traxview-TableWindow'>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Received" {...a11yProps(0)} className='Traxview-Tabs' />
                            <Tab label="Sent" {...a11yProps(1)} className='Traxview-Tabs' />
                        </Tabs>
                    </Box>
                    <div className='Traxview-Table-Panel'>
                        <TabPanel value={tabValue} index={0} className='Trax-view-Table-container'>
                            <Table aria-label="simple table" >
                                <TableHead className='Traxview-Table-header'>
                                    <TableRow>
                                        <TableCell className='Traxview-table-heading'>Name</TableCell>
                                        <TableCell className='Traxview-table-heading'>Sender</TableCell>
                                        <TableCell className='Traxview-table-heading'>Email</TableCell>
                                        <TableCell className='Traxview-table-heading'>Sent</TableCell>
                                        <TableCell className='Traxview-table-heading'>Expire</TableCell>
                                        <TableCell className='Traxview-table-heading'>Images display</TableCell>
                                        <TableCell className='Traxview-table-heading'>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sharedTrax.received.slice((receivedPage - 1) * perPage, ((receivedPage - 1) * perPage) + perPage).map((row) => <TableRow sx={{
                                        // "& .MuiTableCell-root": {
                                        //     color: expireDateCheck(row.share.expiresAt) ? "red" : "green"
                                        // }
                                    }} hover key={row.share.shareId}>
                                        <TableCell align='center' className='Traxview-table-rowreceiveddata'> {row.share.name} </TableCell>
                                        <TableCell align='center' className='Traxview-table-rowreceiveddata'> {row.sender.firstname} {row.sender.lastname} </TableCell>
                                        <TableCell align='center' className='Traxview-table-rowreceiveddata'> {row.sender.email}</TableCell>
                                        {/* <TableCell align='center'> {moment(row.share.sentOn).format("YYYY-MM-DD h:mm:ss a")} </TableCell> */}
                                        <TableCell align='center' className='Traxview-table-rowreceiveddata'> {moment(row.share.sentOn).format("YYYY-MM-DD")} </TableCell>
                                        {/* <TableCell align='center'> {moment(row.share.expiresAt).format("YYYY-MM-DD h:mm:ss a")} </TableCell> */}
                                        <TableCell align='center' className='Traxview-table-rowreceiveddata'> {moment(row.share.expiresAt).format("YYYY-MM-DD")} </TableCell>
                                        <TableCell align='center' className='Traxview-table-rowreceiveddata'> {row.share.isSharingImages ? "Yes" : "No"} </TableCell>
                                        <TableCell align='center' className='Traxview-table-rowreceiveddata'>
                                            {!expireDateCheck(row.share.expiresAt) && (<VisibilityIcon onClick={() => history.push(`/shared/${row.share.shareId}`)} style={{
                                                cursor: 'pointer',
                                            }} />

                                            )}
                                        </TableCell>
                                    </TableRow>)}

                                </TableBody>
                            </Table>

                            <div className='Traxview-table-pagination'>
                                <Pagination
                                    sx={{
                                        "& .Mui-selected:hover": {
                                            backgroundColor: '#25478e',
                                            color: '#fff',
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor: '#25478e !important',
                                            color: '#fff',
                                        }
                                    }}
                                    page={receivedPage} onChange={receivedHandleChange} count={Math.ceil(sharedTrax.received.length / perPage)} variant="outlined" shape="rounded" />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} className='Trax-view-Table-container'>
                            <Table aria-label="simple table"  >
                                <TableHead className='Traxview-Table-header'>
                                    <TableRow>
                                        <TableCell className='Traxview-table-heading'>Name</TableCell>
                                        <TableCell className='Traxview-table-heading'>Receiver</TableCell>
                                        {/* <TableCell>Sender</TableCell>
                                                        <TableCell>Email</TableCell> */}
                                        <TableCell className='Traxview-table-heading'>Sent</TableCell>
                                        <TableCell className='Traxview-table-heading'>Expire</TableCell>
                                        <TableCell className='Traxview-table-heading'>Images display</TableCell>
                                        <TableCell className='Traxview-table-heading'>Actions</TableCell>


                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sharedTrax.sent.slice((sentPage - 1) * perPage, ((sentPage - 1) * perPage) + perPage).map((row) => <TableRow hover key={row.shareId}>
                                        <TableCell align='center' className='Traxview-table-rowsentdata'> {row.name} </TableCell>
                                        <TableCell align='center' className='Traxview-table-rowsentdata'> {row.recipients.map(li => {
                                            if (li.firstname) {
                                                return li.firstname + " " + li.lastname
                                            } else {
                                                return li.email;
                                            }
                                        }).join(",")} </TableCell>
                                        {/* <TableCell align='center'> {moment(row.sentOn).format("YYYY-MM-DD h:mm:ss a")} </TableCell>
                                        <TableCell align='center'> {moment(row.expiresAt).format("YYYY-MM-DD h:mm:ss a")} </TableCell> */}
                                        <TableCell align='center' className='Traxview-table-rowsentdata'> {moment(row.sentOn).format("YYYY-MM-DD")} </TableCell>
                                        <TableCell align='center' className='Traxview-table-rowsentdata'> {moment(row.expiresAt).format("YYYY-MM-DD")} </TableCell>
                                        <TableCell align='center' className='Traxview-table-rowsentdata'> {row.isSharingImages ? "Yes" : "No"} </TableCell>
                                        <TableCell align='center' className='Traxview-table-rowsentdata'>
                                            <Edit onClick={() => {
                                                let milliseconds = null;
                                                if (new Date(moment().add(1, 'hours')).getTime() - new Date().getTime() === row.expiresAtInMilliSeconds) {
                                                    milliseconds = 1;
                                                } else if (new Date(moment().add(24, 'hours')).getTime() - new Date().getTime() === row.expiresAtInMilliSeconds) {
                                                    milliseconds = 2;
                                                } else if (new Date(moment().add(7, 'days')).getTime() - new Date().getTime() === row.expiresAtInMilliSeconds) {
                                                    milliseconds = 3;
                                                } else if (new Date(moment().add(14, 'days')).getTime() - new Date().getTime() === row.expiresAtInMilliSeconds) {
                                                    milliseconds = 4;
                                                }
                                                console.log(row)
                                                let endTime = "";
                                                let startTime = "";
                                                if (row.type.toLowerCase() === 'bookmark') {
                                                    const findBookMark = bookmarkList.find(x => x.id === row.bookmark);
                                                    if (findBookMark) {
                                                        endTime = moment(findBookMark.endTime).format('YYYY-MM-DD');
                                                        startTime = moment(findBookMark.startTime).format('YYYY-MM-DD')
                                                    }

                                                }
                                                setEmail(row.recipients.map(x => x.email));

                                                setUpdatePayload({
                                                    type: row.type,
                                                    shareId: row.shareId,
                                                    bookmark: row.bookmark,
                                                    expiresAt: milliseconds,
                                                    startTime: startTime,
                                                    endTime: endTime,
                                                    isSharingImages: row.isSharingImages,
                                                    name: row.name
                                                })
                                                updateModelHandle();

                                            }} style={{
                                                cursor: 'pointer',
                                            }} />
                                            &nbsp;&nbsp;
                                            <Delete onClick={async () => {
                                                const resp = await fetchData({ url: URLS.GET_SHARE.replace("@userId", id).replace("@shareId", row.shareId), method: 'delete', body: null, headersData: "", headerParameters: "" });
                                                if (resp && resp.code && resp.message) {
                                                    messageAlert(resp.message, "error")
                                                } else {
                                                    messageAlert("Record deleted successfully", "success");
                                                    getSentSharedTrax();
                                                }

                                            }} style={{
                                                cursor: 'pointer',
                                            }} /> </TableCell>
                                    </TableRow>)}

                                </TableBody>
                            </Table>
                            <div className='Traxview-table-pagination'>
                                <Pagination
                                    sx={{
                                        "& .Mui-selected": {
                                            backgroundColor: '#25478e !important',
                                            color: '#fff',
                                        }
                                    }}
                                    page={sentPage} onChange={sentHandleChange} count={Math.ceil(sharedTrax.sent.length / perPage)} variant="outlined" shape="rounded" />
                            </div>
                        </TabPanel>
                    </div>

                </div>

            </div>
            {/* Model */}
            <DialogBox
                className='Trax-modal'
                onClose={modelHandle}
                aria-labelledby="customized-dialog-title"
                open={modelPopup}
            // fullWidth
            // maxWidth="md"
            >
                <DialogTitle id="customized-dialog-title"
                    className='Trax-model-Header'
                    onClose={modelHandle} >
                    Share With Whom?

                </DialogTitle>
                <DialogContent dividers className='Trax-modal-container'>
                    <Typography gutterBottom>
                        <Box
                            component="div"
                            sx={{
                                // textAlign: 'center',
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                                '& .MuiFormControl-root': { m: 1 },

                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <FormControl>
                                    {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                    <RadioGroup
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(e) => {
                                            setNewSharePayload({
                                                ...newSharePayload,
                                                type: e.target.value
                                            })
                                        }}
                                        value={newSharePayload.type}
                                    >
                                        <FormControlLabel value="Share an existing bookmark" control={<Radio />} label="Share an existing bookmark" />
                                        <FormControlLabel value="Create A bookmark to share" control={<Radio />} label="Create A bookmark to share" />
                                        <FormControlLabel value="Share all my pins" control={<Radio />} label="Share all my pins" />

                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div>
                                <TextField
                                    label="Email"
                                    value={enterEmail}
                                    placeholder="Type or paste email addresses and press `Enter`..."
                                    onKeyDown={handleKeyDown}
                                    error={error}
                                    helperText={error || "Type or paste email addresses and press `Enter`..."}
                                    onChange={emailHandleChange}
                                    onPaste={handlePaste}
                                />
                            </div>
                            <div>
                                {emails.map(row => <Chip sx={{ m: 1 }} key={row} label={row} onDelete={() => handleDelete(row)} />)}

                                {/* <Chip label="Deletable" onDelete={handleDelete} /> */}
                            </div>
                            <div>

                            </div>
                        </Box>
                    </Typography>

                </DialogContent>
                <DialogActions
                    className='Trax-modal-Footer'
                >
                    <Box>
                        <Button
                            variant="contained" sx={{
                                marginRight: '1rem',
                            }} onClick={resetData}
                            color="primary" > close </Button>

                        <Button
                            disabled={!emails.length}
                            variant="contained" onClick={(e) => {
                                getBookMarksList()
                                setNewSharePayload({
                                    ...newSharePayload,
                                    email: emails,

                                })
                                modelHandle()
                                actionModelHandle()

                            }} color="primary" > Next </Button>
                    </Box>
                </DialogActions>
            </DialogBox>
            {/* Model */}

            {/* Action Model */}
            <DialogBox
                className='Trax-modal'
                onClose={actionModelHandle}
                aria-labelledby="customized-dialog-title"
                open={actionModel}
                fullWidth
            // maxWidth="sm"
            >
                <DialogTitle id="customized-dialog-title"
                    className='Trax-model-Header'
                    onClose={actionModelHandle} >
                    Share

                </DialogTitle>
                <DialogContent className='Trax-modal-container'>
                    {newSharePayload.type === 'Share all my pins' && (<Typography gutterBottom>
                        <div>
                            <TextField
                                className='Trax-Model-fields'
                                name="name"
                                onChange={bookMarkChangeHandler}
                                label="Name"
                                value={createBookMakrkPayload.name}

                            />
                        </div>


                        <FormControl className='Trax-Model-fields'>
                            <InputLabel id="demo-simple-select-label">Expire time</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={createBookMakrkPayload.expiresAt}
                                onChange={(e) => {
                                    setCreateBookMakrkPayload({
                                        ...createBookMakrkPayload,
                                        expiresAt: e.target.value
                                    })
                                }}
                                label="Expire time"
                            >
                                <MenuItem value={1}>1 Hour</MenuItem>
                                <MenuItem value={2}>24 Hours</MenuItem>
                                <MenuItem value={3}>7 Days</MenuItem>
                                <MenuItem value={4}>14 Days</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControlLabel
                            value="start"
                            control={<Switch checked={createBookMakrkPayload.isSharingImages} onChange={(e) => {
                                setCreateBookMakrkPayload({
                                    ...createBookMakrkPayload,
                                    isSharingImages: e.target.checked
                                })
                            }} name="isSharingImages"
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="Share All Photos"
                            labelPlacement="start"
                        />
                    </Typography>)}
                    {newSharePayload.type === 'Share an existing bookmark' && (<Typography gutterBottom>
                        <Box component="div"
                        //  sx={{
                        //     '& .MuiAutocomplete-root': { m: 1, width: '30ch' },
                        //     '& .MuiTextField-root': { m: 1, width: '30ch' },
                        //     '& .MuiFormControl-root': { m: 1, width: '30ch' },
                        //     '& .MuiFormControlLabel-root': { m: 1 },
                        // }}
                        >
                            <Autocomplete
                                sx={{
                                    "& .MuiAutocomplete-paper": {
                                        overflow: "visible"

                                    }
                                }}
                                className='Trax-Model-fields'
                                disablePortal
                                value={bookmarkList.find(row => row.id === createBookMakrkPayload.id)}
                                id="combo-box-demo"
                                onChange={(e, value) => {
                                    setCreateBookMakrkPayload({
                                        ...createBookMakrkPayload,
                                        id: value.id,
                                        name: value.name,
                                    })
                                    // console.log(value)

                                }}
                                getOptionLabel={(option) => option.name}
                                options={bookmarkList}
                                renderInput={(params) => <TextField {...params} label="Select bookmark" />}
                            />

                            <FormControl className='Trax-Model-fields'>
                                <InputLabel id="demo-simple-select-label">Expire time</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={createBookMakrkPayload.expiresAt}
                                    onChange={(e) => {
                                        setCreateBookMakrkPayload({
                                            ...createBookMakrkPayload,
                                            expiresAt: e.target.value
                                        })
                                    }}
                                    label="Expire time"
                                >
                                    <MenuItem value={1}>1 Hour</MenuItem>
                                    <MenuItem value={2}>24 Hours</MenuItem>
                                    <MenuItem value={3}>7 Days</MenuItem>
                                    <MenuItem value={4}>14 Days</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControlLabel
                                value="start"
                                control={<Switch checked={createBookMakrkPayload.isSharingImages} onChange={(e) => {
                                    setCreateBookMakrkPayload({
                                        ...createBookMakrkPayload,
                                        isSharingImages: e.target.checked
                                    })
                                }} name="isSharingImages"
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                                label="Share All Photos"
                                labelPlacement="start"
                            />
                        </Box>
                    </Typography>)}
                    {newSharePayload.type === 'Create A bookmark to share' && (<Typography gutterBottom>
                        <Box
                            component="div"
                            sx={{
                                // textAlign: 'center',

                                '& .MuiTextField-root': { m: 1, width: '30ch' },
                                '& .MuiFormControl-root': { m: 1, width: '30ch' },
                                '& .MuiFormControlLabel-root': { m: 1 },
                                // '& .MuiInputBase-root': { width: '50ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                {emails.map(row => <Chip sx={{ m: 1 }} key={row} label={row} onDelete={() => handleDelete(row)} />)}

                                {/* <Chip label="Deletable" onDelete={handleDelete} /> */}
                            </div>
                            <div>
                                <TextField
                                    className='Trax-Model-2-fields'
                                    name="name"
                                    onChange={bookMarkChangeHandler}
                                    label="Bookmark Name"
                                    value={createBookMakrkPayload.name}

                                />
                            </div>
                            <div>
                                <TextField
                                    name='startTime'
                                    onChange={bookMarkChangeHandler}
                                    value={createBookMakrkPayload.startTime}
                                    type="date"
                                    label="From Date"

                                />
                                <TextField
                                    name='endTime'
                                    onChange={bookMarkChangeHandler}
                                    value={createBookMakrkPayload.endTime}
                                    type="date"
                                    label="To Date"

                                />

                                <FormControl >
                                    <InputLabel id="demo-simple-select-label">Expire time - {createBookMakrkPayload.expireTime}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e) => {
                                            setCreateBookMakrkPayload({
                                                ...createBookMakrkPayload,
                                                expiresAt: e.target.value
                                            })
                                        }}
                                        value={createBookMakrkPayload.expiresAt}
                                        label="Expire time"
                                    >
                                        <MenuItem value={1}>1 Hour</MenuItem>
                                        <MenuItem value={2}>24 Hours</MenuItem>
                                        <MenuItem value={3}>7 Days</MenuItem>
                                        <MenuItem value={4}>14 Days</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControlLabel
                                    value="start"
                                    control={<Switch checked={createBookMakrkPayload.isSharingImages} onChange={(e) => {
                                        setCreateBookMakrkPayload({
                                            ...createBookMakrkPayload,
                                            isSharingImages: e.target.checked
                                        })
                                    }} name="isSharingImages"
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />}
                                    label="Share All Photos"
                                    labelPlacement="start"
                                />

                            </div>
                        </Box>
                    </Typography>)}
                </DialogContent>
                <DialogActions className='Trax-modal-Footer'>
                    <Button
                        disabled={!emails.length}
                        variant="contained" onClick={(e) => {
                            setCreateBookMakrkPayload({
                                startTime: moment().subtract(5, 'days').format('YYYY-MM-DD'),
                                endTime: moment().format('YYYY-MM-DD'),
                                name: '',
                                isSharingImages: false,
                                expiresAt: 4,
                                isSharingImages: false
                            })
                            modelHandle()
                            actionModelHandle()

                        }} color="primary" > Back </Button>
                    <Button variant="contained" onClick={async (e) => {
                        if (newSharePayload.type === 'Create A bookmark to share') {
                            const payload = {
                                startTime: moment(createBookMakrkPayload.startTime).unix().toString(),
                                endTime: moment(createBookMakrkPayload.endTime).unix().toString(),
                                name: createBookMakrkPayload.name,
                            }
                            const data = await fetchData({ url: URLS.BOOKMARK.replace("@userId", id), method: 'post', body: payload, headersData: "", headerParameters: "" });
                            if (data.code) {
                                if (data.message && data.message.length) {
                                    messageAlert(data.message[0].startTime || data.message[0].name, 'error')
                                }
                                return null;
                            }
                            if (data.success === false) {
                                messageAlert(data.message, 'error')
                                return null;
                            }
                            let milliseconds = 1209600000;
                            if (createBookMakrkPayload.expiresAt === 1) {
                                milliseconds = new Date(moment().add(1, 'hours')).getTime() - new Date().getTime();
                            }
                            if (createBookMakrkPayload.expiresAt === 2) {
                                milliseconds = new Date(moment().add(24, 'hours')).getTime() - new Date().getTime();
                            }
                            if (createBookMakrkPayload.expiresAt === 3) {
                                milliseconds = new Date(moment().add(7, 'days')).getTime() - new Date().getTime();
                            }
                            if (createBookMakrkPayload.expiresAt === 4) {
                                milliseconds = new Date(moment().add(14, 'days')).getTime() - new Date().getTime();
                            }
                            const sharePayload = {
                                "bookmark": data.id,
                                "expiresAt": milliseconds,
                                "isAlwaysOn": 0,
                                "isSharingImages": createBookMakrkPayload.isSharingImages,
                                "name": data.name,
                                "recipients": emails.map(x => { return { email: x } }),
                                "type": "Bookmark"
                            }

                            const myTimeout = setTimeout(() => {
                                resetData()
                                messageAlert("bookmark successfully shared", 'success');
                                getSentSharedTrax()
                            }, 10000);
                            const resp = await fetchData({ url: URLS.SHARE.replace("@userId", id), method: 'post', body: sharePayload, headersData: "", headerParameters: "" });
                            if (resp && resp.length && resp[0] && resp[0].message) {
                                messageAlert(resp[0].message, 'error')
                                clearTimeout(myTimeout);
                                await fetchData({ url: URLS.BOOKMARK_DELETE.replace("@userid", id).replace("@bookmarkid", sharePayload.bookmark), method: 'delete', body: "", headersData: "", headerParameters: "" });
                            }
                            else {
                                clearTimeout(myTimeout);
                                resetData()
                                messageAlert("bookmark successfully shared", 'success');
                                getSentSharedTrax()

                            }
                        } else if (newSharePayload.type === 'Share an existing bookmark') {
                            let milliseconds = 1209600000;
                            if (createBookMakrkPayload.expiresAt === 1) {
                                milliseconds = new Date(moment().add(1, 'hours')).getTime() - new Date().getTime();
                            }
                            if (createBookMakrkPayload.expiresAt === 2) {
                                milliseconds = new Date(moment().add(24, 'hours')).getTime() - new Date().getTime();
                            }
                            if (createBookMakrkPayload.expiresAt === 3) {
                                milliseconds = new Date(moment().add(7, 'days')).getTime() - new Date().getTime();
                            }
                            if (createBookMakrkPayload.expiresAt === 4) {
                                milliseconds = new Date(moment().add(14, 'days')).getTime() - new Date().getTime();
                            }
                            const sharePayload = {
                                "bookmark": createBookMakrkPayload.id,
                                "expiresAt": milliseconds,
                                "isAlwaysOn": 0,
                                "isSharingImages": createBookMakrkPayload.isSharingImages,
                                "name": createBookMakrkPayload.name,
                                "recipients": emails.map(x => { return { email: x } }),
                                "type": "Bookmark"
                            }

                            const myTimeout = setTimeout(() => {
                                resetData()
                                messageAlert("bookmark successfully shared", 'success');
                                getSentSharedTrax()
                            }, 10000);
                            const resp = await fetchData({ url: URLS.SHARE.replace("@userId", id), method: 'post', body: sharePayload, headersData: "", headerParameters: "" });
                            if (resp && resp.length && resp[0] && resp[0].message) {
                                messageAlert(resp[0].message, 'error')
                                clearTimeout(myTimeout);
                                await fetchData({ url: URLS.BOOKMARK_DELETE.replace("@userid", id).replace("@bookmarkid", sharePayload.bookmark), method: 'delete', body: "", headersData: "", headerParameters: "" });
                            }
                            else {
                                clearTimeout(myTimeout);
                                resetData()
                                messageAlert("bookmark successfully shared", 'success');
                                getSentSharedTrax()

                            }
                        } else if (newSharePayload.type === 'Share all my pins') {

                            let milliseconds = 1209600000;
                            if (createBookMakrkPayload.expiresAt === 1) {
                                milliseconds = new Date(moment().add(1, 'hours')).getTime() - new Date().getTime();
                            }
                            if (createBookMakrkPayload.expiresAt === 2) {
                                milliseconds = new Date(moment().add(24, 'hours')).getTime() - new Date().getTime();
                            }
                            if (createBookMakrkPayload.expiresAt === 3) {
                                milliseconds = new Date(moment().add(7, 'days')).getTime() - new Date().getTime();
                            }
                            if (createBookMakrkPayload.expiresAt === 4) {
                                milliseconds = new Date(moment().add(14, 'days')).getTime() - new Date().getTime();
                            }
                            const sharePayload = {
                                "expiresAt": milliseconds.toString(),
                                "isAlwaysOn": true,
                                "isSharingImages": createBookMakrkPayload.isSharingImages,
                                "name": createBookMakrkPayload.name,
                                "recipients": emails.map(x => { return { email: x } }),
                                "type": "Trax"
                            }

                            const myTimeout = setTimeout(() => {
                                resetData()
                                messageAlert("bookmark successfully shared", 'success');
                                getSentSharedTrax()
                            }, 10000);
                            const resp = await fetchData({ url: URLS.SHARE.replace("@userId", id), method: 'post', body: sharePayload, headersData: "", headerParameters: "" });
                            if (resp && resp.length && resp[0] && resp[0].message) {
                                messageAlert(resp[0].message, 'error')
                                clearTimeout(myTimeout);
                                await fetchData({ url: URLS.BOOKMARK_DELETE.replace("@userid", id).replace("@bookmarkid", sharePayload.bookmark), method: 'delete', body: "", headersData: "", headerParameters: "" });
                            }
                            else {
                                clearTimeout(myTimeout);
                                resetData()
                                messageAlert("bookmark successfully shared", 'success');
                                getSentSharedTrax()

                            }
                        }
                    }}> Share</Button>

                </DialogActions>
            </DialogBox>

            {/* Action Model */}

            {/* Update Model */}
            <DialogBox
                className='Trax-modal'
                onClose={updateModelHandle}
                aria-labelledby="customized-dialog-title"
                open={updateModel}
                fullWidth
            // maxWidth="sm"
            >
                <DialogTitle id="customized-dialog-title"
                    className='Trax-model-Header'
                    onClose={updateModelHandle} >
                    Update
                </DialogTitle>
                <DialogContent dividers className='Trax-modal-container' >
                    <Box
                        component="div"
                        sx={{
                            // textAlign: 'center',

                            '& .MuiTextField-root': { m: 1 }
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                fullWidth
                                label="Email"
                                value={enterEmail}
                                placeholder="Type or paste email addresses and press `Enter`..."
                                onKeyDown={handleKeyDown}
                                error={error}
                                helperText={error || "Type or paste email addresses and press `Enter`..."}
                                onChange={emailHandleChange}
                                onPaste={handlePaste}
                            />
                        </div>
                        <div>
                            {emails.map(row => <Chip sx={{ m: 1 }} key={row} label={row} onDelete={() => handleDelete(row)} />)}

                            {/* <Chip label="Deletable" onDelete={handleDelete} /> */}
                        </div>
                    </Box>
                    <Box
                        component="div"
                        sx={{
                            // textAlign: 'center',

                            '& .MuiTextField-root': { m: 1, width: '30ch' },
                            '& .MuiFormControl-root': { m: 1, width: '30ch' },
                            '& .MuiFormControlLabel-root': { m: 1 },
                            // '& .MuiInputBase-root': { width: '50ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                name="name"
                                onChange={updateChangeHandler}
                                label="Bookmark Name"
                                value={updatePayload.name}

                            />
                        </div>
                        {updatePayload.type && updatePayload.type.toLowerCase() === 'bookmark' && (<div>
                            <TextField
                                name='startTime'
                                onChange={updateChangeHandler}
                                value={updatePayload.startTime}
                                type="date"
                                label="From Date"

                            />
                            <TextField
                                name='endTime'
                                onChange={updateChangeHandler}
                                value={updatePayload.endTime}
                                type="date"
                                label="To Date"

                            />
                        </div>)}
                        <div>
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Expire time - {createBookMakrkPayload.expireTime}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="expiresAt"
                                    onChange={updateChangeHandler}
                                    value={updatePayload.expiresAt}
                                    label="Expire time"
                                >
                                    <MenuItem value={1}>1 Hour</MenuItem>
                                    <MenuItem value={2}>24 Hours</MenuItem>
                                    <MenuItem value={3}>7 Days</MenuItem>
                                    <MenuItem value={4}>14 Days</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControlLabel
                                value="start"
                                control={<Switch checked={updatePayload.isSharingImages} onChange={(e) => {
                                    setUpdatePayload({
                                        ...updatePayload,
                                        isSharingImages: e.target.checked
                                    })
                                }} name="isSharingImages"
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                                label="Share All Photos"
                                labelPlacement="start"
                            />

                        </div>
                    </Box>
                </DialogContent>
                <DialogActions
                    className='Trax-modal-Footer'
                >
                    <Button
                        variant="contained" onClick={(e) => {
                            setEmail([]);
                            setEnterEmail("");
                            setUpdatePayload({
                                shareId: "",
                                bookmark: "",
                                expiresAt: 4,
                                startTime: "",
                                endTime: "",
                                isSharingImages: false,
                                name: ""
                            })
                            updateModelHandle()
                        }}
                        color="primary" > close </Button>
                    <Button disabled={!emails.length || !updatePayload.name} variant="contained" onClick={async () => {
                        let milliseconds = 1209600000;
                        if (updatePayload.expiresAt === 1) {
                            milliseconds = new Date(moment().add(1, 'hours')).getTime() - new Date().getTime();
                        }
                        if (updatePayload.expiresAt === 2) {
                            milliseconds = new Date(moment().add(24, 'hours')).getTime() - new Date().getTime();
                        }
                        if (updatePayload.expiresAt === 3) {
                            milliseconds = new Date(moment().add(7, 'days')).getTime() - new Date().getTime();
                        }
                        if (updatePayload.expiresAt === 4) {
                            milliseconds = new Date(moment().add(14, 'days')).getTime() - new Date().getTime();
                        }

                        const uPayload = {
                            name: updatePayload.name,
                            isSharingImages: updatePayload.isSharingImages,
                            expiresAt: milliseconds,
                            type: updatePayload.type,
                            isAlwaysOn: true,
                            recipients: emails.map(x => {
                                return {
                                    email: x
                                }
                            })

                        }

                        if (updatePayload.type.toLowerCase() === "bookmark") {
                            uPayload['startTime'] = moment(updatePayload.startTime).unix().toString();
                            uPayload['endTime'] = moment(updatePayload.endTime).unix().toString();
                            uPayload['bookmark'] = updatePayload.bookmark;
                        }

                        const resp = await fetchData({ url: URLS.GET_SHARE.replace("@userId", id).replace("@shareId", updatePayload.shareId), method: 'put', body: uPayload, headersData: "", headerParameters: "" });
                        if (resp && resp.code && resp.message) {
                            messageAlert(resp.message, "error")
                        } else {
                            messageAlert("Record updated successfully", "success");
                            getSentSharedTrax();
                            setEmail([]);
                            setEnterEmail("");
                            setUpdatePayload({
                                shareId: "",
                                bookmark: "",
                                expiresAt: 4,
                                startTime: "",
                                endTime: "",
                                isSharingImages: false,
                                name: ""
                            })
                            updateModelHandle()
                        }

                    }} > Update</Button>
                </DialogActions>
            </DialogBox>

            {/* update Model */}
        </div >
    )
}
export default SharedTrax;