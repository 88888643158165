import React, { createContext, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const userObject = {
    birthDate:"",
    email:"",
    firstname:"",
    lastname:"",
    mobile:'',
    gender:'',
    image_url:'',
    role:''
}
const LoaderAndToasterContext = createContext({
    setIsLoader: (con) => { },
    messageAlert: (message, type) => { },
    setIsAuth : (status) => { },
    isAuth:String,
    setCurrentUseData:(userObject) => { },
    currentUserData:userObject
    
});

export const LoaderAndToasterProvider = ({ children }) => {
    const [isLoader, setIsLoader] = useState(false);
    const [currentUserData, setCurrentUseData] = useState(userObject);
    const [isAuth, setIsAuth] = useState(false);
    const messageAlert = (message, type = "success") => {
        toast[type](message);
    }
    return <LoaderAndToasterContext.Provider value={{
        currentUserData, 
        setCurrentUseData,
        setIsLoader: setIsLoader,
        messageAlert: messageAlert,
        setIsAuth: setIsAuth,
        isAuth:isAuth
    }} >

        <Backdrop
            sx={{ color: '#fff', zIndex: 9999 }}
            open={isLoader}
        >
            <CircularProgress color="inherit" />

        </Backdrop>
        <ToastContainer />
        {children}
    </LoaderAndToasterContext.Provider>



}

export default LoaderAndToasterContext;