import { useContext, useState } from "react";
import LoaderContext from '../../Context/LoaderAndToaster';
import axios from "axios";
import Utf8 from 'crypto-js/enc-utf8';
import Base64 from 'crypto-js/enc-base64';
import { getUserData } from '../../Utlities/utlities';

const useAxios = () => {
    const { setIsLoader } = useContext(LoaderContext);
    const [error, setError] = useState("");

    const fetchData = ({ url, method, body = null, headersData = "",headerParameters="" }) => {
        let currenUrl = url;
        // let response = true;
        const { token, secretKey, apiKey } = getUserData();

        let reqHeaders = {
            'Content-Type': 'application/json',
            'user-agent': 'APIClient',
        }
        if (headersData) {
            var data = Base64.stringify(Utf8.parse(headersData));
            reqHeaders["basic"] = data;
        } else {
            if (secretKey && token && token.token) {
                const data = Base64.stringify(Utf8.parse(secretKey + ':' + token.token));
                // console.log(data);
                reqHeaders["basic"] = data;
            }
        }



        if (apiKey) {
            reqHeaders['apiKey'] = apiKey;
            if (currenUrl.includes("v2.0.1")) {
                if(currenUrl.includes("?")){
                    currenUrl += `&apiKey=${apiKey}`
                }else {
                currenUrl += `?apiKey=${apiKey}`
                }
            }


        }
        if(headerParameters)
        {
            reqHeaders={
                ...reqHeaders,
                ...headerParameters
            }
        }
        const m = ['get', 'delete'];
        setIsLoader(true)
        return new Promise((resolve) => {
            axios[method](currenUrl, m.includes(method) ? { headers: reqHeaders } : body, { headers: reqHeaders,timeout: 5000 })
                .then((res) => {
                    resolve(res.data);
                    // response = false;
                })
                .catch((err) => {
                    // response = false;
                    if (err && err.response && err.response.data) {
                        resolve(err.response.data)
                        setError(err.response.data)
                    } else {
                        if("timeout of 5000ms exceeded" === err.message)
                        {
                            resolve()

                        }else {
                        // console.log(err.message)
                        resolve(err)
                        setError(err);
                        }
                    }
                })
                .finally(() => {
                    setIsLoader(false);
                    // if(response)
                    // {
                    //     resolve("")
                    // }
                });
        })

    }
    return { error, fetchData };

}
export default useAxios;